import dayjs from "dayjs";
import React from "react";
import ReactApexChart from "react-apexcharts";

const MyChart = ({ graphData, name, color }) => {
  const date = graphData?.map((item) =>
    dayjs(item?.[0]).format("YYYY-MM-DDTHH:mm:ss")
  );
  const price = graphData?.map((item) => item?.[1]);
  console.log(price);

  const chartData = {
    series: [
      {
        name: name,
        data: price,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        show: true,
      },
      xaxis: {
        type: "datetime",
        categories: date,
        enabled: false,
        labels: {
          show: false,
          axisTicks: false,
          style: {
            colors: "red",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        decimalsInFloat: price?.[0] < 0 ? 6 : price?.[0] < 10 ? 4 : 0,
        labels: {
          show: false,
          enabled: false,
          axisTicks: false,
          style: {
            colors: "#94a3b8",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      colors: [color],
      tooltip: {
        enabled: true,
      },
      legend: {
        enabled: false,
      },
      toolbar: {
        enabled: false,
      },
      markers: {
        size: 0,
        colors: undefined,
        strokeWidth: 0.5,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        discrete: [],
        shape: "circle",
        radius: 10,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      grid: {
        show: false,

        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
    chart: {
      background: "none",
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={195}
      />
    </div>
  );
};

export default MyChart;
