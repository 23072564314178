import React, { useEffect } from 'react';
import dashboard from '../../assests/images/colorIcon.png';
import chart from '../../assests/images/Chart.png';
import pending from '../../assests/images/Combined-Shape.png';
import support from '../../assests/images/Wallet.png';
import activeDashboard from '../../assests/icons/activedashboard.png';
import activeChart from '../../assests/icons/activechart.png';
import activeTransaction from '../../assests/icons/activetransaction.png';
import activeSupport from '../../assests/icons/activeuser.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import handleRefreshToken from '../../utils/RefreshToken'
import { useTranslation } from 'react-i18next';

const SideBar = ({ setShowSiderbar }) => {

    const location = useLocation();
    const pathname = location.pathname;
    const { t } = useTranslation();
    const { The, Trade, Market } = t("dashboard", { returnObjects: true });
    const { Dashboard, User, AgencyManagement, PendingTransactions, NeedHelp, Contact, SupportTicket } = t("AdminSidebar", { returnObjects: true });
    const navItem = [
        {
            icon: dashboard,
            activeIcon: activeDashboard,
            title: Dashboard,
            url: '/admin/dashboard'
        },
        {
            icon: pending,
            activeIcon: activeSupport,
            title: User,
            url: 'managment'
        },
        {
            icon: chart,
            activeIcon: activeChart,
            title: AgencyManagement,
            url: 'agencymanagement'
        },
        {
            icon: pending,
            activeIcon: activeTransaction,
            title: PendingTransactions,
            url: 'transaction'
        },
      
    ]

    useEffect(() => {
        const time = Number(localStorage.getItem('time'));
        const interval = setInterval(() => {
            if (time + 40 * 60 * 1000 < Date.now()) {
                handleRefreshToken();
                localStorage.setItem('time', Date.now());
                localStorage.getItem('timestamp', Date.now() + 1000 * 60 * 60);
            }
        }, 5000)
        return () => clearInterval(interval);
    })




    return (
        <div className='h-screen bg-theme-white dark:bg-dark overflow-y-auto scroll-smooth'>
            <div className='px-6 py-8'>
                <div className={`flex justify-center dark:text-white ${pathname.slice(0, 6) == '/admin' ? '' : 'h-28'}`}>
                    {/* <img className={` ${pathname.slice(0, 6) == '/admin' ? 'w-[60%]' : 'h-28'}   `} src={Logo} alt="logo" /> */}
                  
                        <h5 className="font-nunito font-bold text-2xl ms-2 mt-3 text-nowrap dark:text-white">
                        {The} {Trade} {Market}
                        </h5>
                   
                    <BiArrowBack className='visible xl:hidden dark:text-white' onClick={() => setShowSiderbar(false)} size={24} />
                </div>
                <div className='my-10'>
                    {navItem.map(({ title, icon, url, activeIcon }, index) => {
                        return (
                            <NavLink onClick={() => setShowSiderbar(false)} className={`${pathname.includes(url) ? 'bg-hover dark:hover:bg-dark-active dark:bg-dark-active' : (pathname === '/admin' && index === 0) ? 'bg-hover dark:hover:bg-dark-active dark:bg-dark-active' : ''} hover:bg-hover dark:hover:bg-dark-hover flex h-12 m-2 items-center p-3 gap-3 rounded-xl transition-colors duration-300`
                            } to={url} key={index} >
                                <img src={pathname.includes(url) ? icon : icon} alt="icon" />
                                <p className=' text-[#8F95B2] text-base font-primary font-semibold'>{title}</p>
                            </NavLink>
                        )
                    })}

                </div>
                <div className='w-full flex justify-center'>
                    <div className='w-full h-[1px] bg-line' />
                </div>


                <div className="p-3 fixed bottom-0">
                    <h1 className="text-sm mt-2 dark:text-white font-semibold">{NeedHelp}</h1>
                    <p className="text-sm text-[#8F95B2] font-semibold">{Contact}</p>
                    <Link
                        className="flex h-10 items-center p-3 gap-3 mt-3 rounded-md transition-colors duration-300 dark:hover:bg-dark-hover hover:bg-hover bg-dark"
                        to={'/admin/support'}
                    >
                        <img className="brithness-class" src={support} alt="" />
                        <h1 className="text-base whitespace-nowrap text-white">{SupportTicket}</h1>
                    </Link>
                </div>
               
            
            </div>
        </div >
    )
}

export default SideBar;