import React from 'react'
// import "./index.css"
import search from '../../assests/icons/Search.png'
import user from '../../assests/userimg.png'
import { useTranslation } from 'react-i18next';
const Card = ({ details }) => {


    const { t } = useTranslation();
    const { Offline, LastSeen, ManageUser } = t("UserManagment", { returnObjects: true });

   
    return (
        <>
            <div className='w-full max-w-xs h-[340px] transition-all hover:shadow-xl duration-300 dark:bg-dark dark:border dark:border-[#334155] hover:border-[#6c5dd3] bg-[#E6E8F0] rounded-[10px] border-1 border-gray-400'>
                <div className='h-20 w-full flex justify-center pl-9 flex-col dark:bg-[#141f38] bg-[#E6E8F0] rounded-t-[10px]'>
                    <p className='text-base font-medium leading-6 font-nunito dark:text-white'>{Offline}</p>
                    <p className='text-[#6B6B6B] text-sm font-nunito dark:text-gray-400 font-medium'>{LastSeen}: 2023-09-14 19:04:54</p>
                </div>
                <div className='flex items-center flex-col my-4'>
                    <div className='w-20 h-20 rounded-full dark:border dark:border-[#334155] dark:bg-[#141f38] bg-[#D9D9D9]'>
                    </div>
                    <p className='text-lg font-bold font-nunito dark:text-white mt-1'>{details.name}</p>
                    <p className='font-nunito text-sm font-medium dark:text-gray-400'>{details.email}</p>
                </div>
                <div className='w-full flex justify-center'>
                    <button className='w-[80%] h-10 dark:bg-[#141f38] bg-[#E6E8F0] border-1 border-gray-400 dark:border-[#334155] rounded-lg'>
                        <p className='text-base dark:text-white text-[#081735]'>{ManageUser}</p>
                    </button>
                </div>
            </div>
        </>


    )
}
export default Card;