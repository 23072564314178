import React, { useEffect, useState } from 'react'
import Bitcoin from '../../../assests/icons/bitcoinimage.png'
import { BsThreeDots } from 'react-icons/bs'
import { BiSolidLock } from 'react-icons/bi'
import { Button, Dropdown, Input, InputNumber, Select, Spin } from 'antd'
import { Link } from 'react-router-dom'
import ApexChart from '../../../components/chart'
import { useDispatch } from 'react-redux'
import { changeHeader } from '../../../redux/reducer/headerSlice'
import { PiCoinFill, PiShoppingCartBold } from 'react-icons/pi'
import { useLocation } from 'react-router-dom'
import handlePost from '../../../utils/Post'
import { BASE_URL } from '../../../utils/config'
import axios from 'axios'
import QRCode from "react-qr-code";
import handleGet from '../../../utils/Get'
import image from '../../../assests/images/Arrow 2.png';
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'


const AssestsManage = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const location = useLocation();

    const data = location?.state


    const [buttonManage, setButtonManage] = useState({ recieve: true, send: false, exchange: false })
    const [sendData, setSendData] = useState({ to_address: '', asset_id: data?.id, amount: '' })
    const [loading, setLoading] = useState(false)
    const [graphData, setGraphData] = useState('');
    const [transaction, setTransaction] = useState('');
    const { t } = useTranslation();
    const { welcomeback} = t("dashboard", { returnObjects: true });

 

    useEffect(() => {
        dispatch(changeHeader(`${user.name}, ${welcomeback}`));
    }, [user, welcomeback])


    useEffect(() => {
        const fetchBitcoinData = async () => {
            try {
                const response = await axios.get(
                    `https://api.coingecko.com/api/v3/coins/${(data?.name).toLowerCase() === 'xrp'
                        ? 'xrp-classic-new'
                        : (data?.name).toLowerCase() === 'fiat'
                            ? 'tether'
                            : (data?.name).toLowerCase()
                    }/market_chart?vs_currency=usd&days=120&interval=daily`
                );
                setGraphData(response?.data?.prices);
            }
            catch (error) {
                console.log(error.message);
            }
        };

        const fetchTransactions = async () => {
            const response = await handleGet(`${BASE_URL}active/user/transaction`);
            setTransaction(response?.transactions)
        };

        fetchTransactions();
        fetchBitcoinData();
    }, [])

    const [selectedOption, setSelectedOption] = useState('IBAN');

    const handleChange = (selected) => {
        setSelectedOption(selected);
    };

    const paymentOptions = [
        { value: 'IBAN', label: 'IBAN' },
        { value: 'BSB', label: 'BSB/Acc Number' },
        { value: 'eTransfer', label: 'E-Transfer' },
    ];




    const [formData, setFormData] = useState({
        IBAN: {
            means_of_transaction: 'IBAN',
            fullname: '',
            bank_name: '',
            iban_number: '',
        },
        BSB: {
            means_of_transaction: 'BSB/Acc Number',
            bsb_number: '',
            account_number: '',
            fullname: '',
            bank_name: '',
        },
        eTransfer: {
            means_of_transaction: 'E-Transfer',
            fullname: '',
            email: '',
            bank_name: '',
        },
    });


    const handleInputChange = (e, method, field) => {
        const value = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            [method]: {
                ...prevData[method],
                [field]: value,
            },
        }));
    };


    const handleSend = async () => {
        setLoading(true);
        try {
            const response = await handlePost(`${BASE_URL}user/transaction/send`, sendData);
            setLoading(false);
            setSendData('')
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const [flatAmount, setAmount] = useState('')

    const Handleflatdep = async () => {
        const maindata = {
            amount: flatAmount,
            sent_at: null,
            hidden: null,
            txt_id: null,
            from_address: null,
            note: null,
            user_id: user?.id,
            asset_id: data?.id,
            type: 'withdraw',
            ...formData[selectedOption],
        };
       
        setLoading(true);
        try {
            const response = await handlePost(`${BASE_URL}transaction/make/flatdeposit`, maindata);
            setFormData('')
            setAmount('')
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }




    return (
        <div className='w-full h-full grid grid-cols-3 gap-4 overflow-x-hidden'>
            <div className='md:col-span-1 col-span-3 row-span-3 h-fit dark:bg-dark dark:border dark:border-[#334155] bg-theme-white rounded-md p-4'>
                <div className='flex justify-between items-center'>
                    <div className='w-fit h-fit p-[10px] rounded-full bg-[#bdb4f5]'>
                        <img className='w-7 h-7' src={data?.symbol} alt="Bitcoin" />
                    </div>
                    <p className='font-nunito font-semibold text-lg dark:text-dark-text'>Manage {data?.name}</p>
                    <Dropdown className='text-white bg-dark' trigger={['click']} placement='bottomRight' dropdownRender={() => (
                        <div className='w-64 p-2 h-28 bg-theme-white rounded-lg shadow-2xl'>
                            <div className='flex items-center transition-all duration-300 gap-2 p-2 rounded-md cursor-pointer'>
                                <PiCoinFill color='gray' size={24} />
                                <div className='cursor-pointer'>
                                    <p className='leading-4 font-nunito text-black font-medium'>Exchange</p>
                                    <p className='leading-4 font-nunito text-gray-400 text-xs'>Exchange your assets</p>
                                </div>
                            </div>
                            <div className='flex items-center transition-all duration-300 gap-2 p-2 rounded-md cursor-pointer'>
                                <PiShoppingCartBold color='gray' size={24} />
                                <div>
                                    <p className='leading-4 font-nunito text-black font-medium'>Buy Crypto</p>
                                    <p className='leading-4 font-nunito text-gray-400 text-xs'>Buy crypto with your card or bank</p>
                                </div>
                            </div>
                        </div>)}>
                        <Button className='rounded-full w-fit h-fit p-1 transition-transform duration-300 hover:rotate-90'>
                            <BsThreeDots size={24} />
                        </Button>
                    </Dropdown>
                </div>
                <p className='font-nunito text-gray-400 mt-4 font-medium leading-4'>Asset Balance</p>
                <p className='font-nunito text-xl font-bold dark:text-dark-text text-[#000]'>{data?.amount} {data?.short_name}</p>
                <div className='mt-4 flex justify-between items-center font-nunito'>
                    <p className='dark:text-dark-text'>Status</p>
                    <p className='text-gray-400'>Active</p>
                </div>
                <div className='mt-3 flex justify-between items-center font-nunito'>
                    <p className='dark:text-dark-text'>Status</p>
                    <p className='text-gray-400'>{dayjs().format('MMM DD[th], YYYY')}</p>
                </div>
                <div className='mt-3 flex justify-between items-center font-nunito'>
                    <p className='dark:text-dark-text'>Status</p>
                    <p className='text-gray-400 flex items-center gap-1'><BiSolidLock className='bg-dark text-white rounded w-6 h-6 p-1' />AES-256</p>
                </div>
            </div>
            <div className='w-full md:col-span-2 col-span-3 row-span-5 h-fit dark:bg-dark dark:border dark:border-[#334155] bg-theme-white rounded-md p-4'>
                {graphData ?
                    <>
                        <p className="text-lg font-nunito font-bold dark:text-white"> {data?.short_name} Price Chart</p>
                        <ApexChart graphData={graphData} name={data?.short_name} />
                    </> : <div className='w-full flex justify-center'><Spin size='large' /></div>
                }
            </div>
            <div className='md:col-span-1 col-span-3 row-span-5 bg-theme-white dark:bg-dark dark:border dark:border-[#334155] h-fit p-4 rounded-md'>
                <div className='flex justify-center gap-2'>
                    <button onClick={() => setButtonManage({ recieve: true, send: false, exchange: false })} className={`${buttonManage.recieve ? 'bg-[#7EBA00] text-white dark:bg-[#6C5DD3] dark:text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-24 p-2 rounded-lg font-nunito`}>Recieve</button>
                    <button onClick={() => setButtonManage({ recieve: false, send: true, exchange: false })} className={`${buttonManage.send ? 'bg-[#7EBA00] text-white dark:bg-[#6C5DD3] dark:text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-24 p-2 rounded-lg font-nunito`}>Send</button>
                    <button onClick={() => setButtonManage({ recieve: false, send: false, exchange: true })} className={`${buttonManage.exchange ? 'bg-[#7EBA00] text-white dark:bg-[#6C5DD3] dark:text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-24 p-2 rounded-lg font-nunito`}>Exchange</button>
                </div>
                <div>
                    {buttonManage.recieve &&
                        <div className='mt-4 w-full flex items-center flex-col'>
                            {data?.address ?
                                <QRCode
                                    size={128}
                                    style={{ height: 120, width: 120 }}
                                    value={data?.address}
                                    viewBox={`0 0 128 128`}
                                /> :
                                <div className='w-40 h-40 bg-[#bebebe] rounded-lg p-2' />}
                            <p className='w-full text-xs py-3 mt-3 flex justify-center h-fit font-nunito bg-[#d3d3d3] dark:text-white rounded-lg my-1 dark:bg-[#2a364b]'>{data?.address ? data?.address : `Contact Our Support For Address`}</p>
                            <Button disabled className='w-full mb-2 py-2 flex justify-center h-fit font-nunito bg-dark text-white'>Generating Address...</Button>
                            <p className='font-nunito text-xs text-[#64748b] font-semibold text-center dark:text-dark-text'>Please wait while we generate your address, this may take a up to a hour.</p>
                        </div>
                    }
                    {buttonManage.send && (
                        <>
                            {data?.name === 'Fiat' ? (
                                <>

                                    <div className="mt-3">
                                        <Select
                                            className="w-full"
                                            value={selectedOption}
                                            onChange={handleChange}
                                            options={paymentOptions}
                                        />

                                        <div className="mt-4">
                                            {selectedOption === 'IBAN' && (
                                                <div>
                                                    <Input
                                                        className=""
                                                        value={flatAmount}
                                                        placeholder="Amount"
                                                        size="large"
                                                        onChange={(e) => setAmount(e.target.value)}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.IBAN?.fullname}
                                                        placeholder="Full Name"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'IBAN', 'fullname')}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.IBAN?.iban_number}
                                                        placeholder="Enter IBAN number"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'IBAN', 'iban_number')}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.IBAN?.bank_name}
                                                        placeholder="Bank Name"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'IBAN', 'bank_name')}
                                                    />
                                                </div>
                                            )}

                                            {selectedOption === 'BSB' && (
                                                <div>
                                                    <Input
                                                        className=""
                                                        value={flatAmount}
                                                        placeholder="Amount"
                                                        size="large"
                                                        onChange={(e) => setAmount(e.target.value)}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.BSB?.bsb_number}
                                                        placeholder="BSB Number"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'BSB', 'bsb_number')}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.BSB?.account_number}
                                                        placeholder="Account Number"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'BSB', 'account_number')}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.BSB?.fullname}
                                                        placeholder="Full Name"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'BSB', 'fullname')}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.BSB?.bank_name}
                                                        placeholder="Bank Name"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'BSB', 'bank_name')}
                                                    />
                                                </div>
                                            )}

                                            {selectedOption === 'eTransfer' && (
                                                <div>
                                                    <Input
                                                        className=""
                                                        value={flatAmount}
                                                        placeholder="Amount"
                                                        size="large"
                                                        onChange={(e) => setAmount(e.target.value)}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.eTransfer?.fullname}
                                                        placeholder="Full Name"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'eTransfer', 'fullname')}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.eTransfer?.email}
                                                        placeholder="Email"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'eTransfer', 'email')}
                                                    />
                                                    <Input
                                                        className="mt-2"
                                                        value={formData?.eTransfer?.bank_name}
                                                        placeholder="Bank Name"
                                                        size="large"
                                                        onChange={(e) => handleInputChange(e, 'eTransfer', 'bank_name')}
                                                    />
                                                </div>
                                            )}
                                            <Button
                                                loading={loading}
                                                onClick={Handleflatdep}
                                                size='large'
                                                className='w-full font-nunito bg-[#6c5dd3] text-white my-2 mt-2'
                                            >
                                                Send {data?.short_name}
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='mt-4'>
                                        <div className='mb-2'>
                                            <span className='text-[#64748b] dark:text-dark-text text-sm font-nunito'>To</span>
                                            <Input
                                                placeholder='Enter Address'
                                                value={sendData?.to_address}
                                                onChange={e => setSendData({ ...sendData, to_address: e.target.value })}
                                                size='large'
                                            />
                                        </div>
                                        <div className='my-2'>
                                            <span className='text-[#64748b] dark:text-dark-text text-sm font-nunito'>Amount</span>
                                            <div className='flex'>
                                                <InputNumber
                                                    keyboard={false}
                                                    min={0}
                                                    placeholder='Enter Amount'
                                                    value={sendData?.amount}
                                                    onChange={e => setSendData({ ...sendData, amount: e })}
                                                    className='w-full'
                                                    type='number'
                                                    size='large'
                                                />
                                                <Button
                                                    onClick={() => setSendData({ ...sendData, amount: data?.amount })}
                                                    className='font-nunito bg-[#6c5dd3] text-white'
                                                    size='large'
                                                >
                                                    Max
                                                </Button>
                                            </div>
                                        </div>
                                        <p className='my-2 text-xs font-nunito dark:text-dark-text text-[#657892]'>
                                            Funds will be sent for approval by administrator.
                                        </p>
                                        <Button
                                            loading={loading}
                                            onClick={handleSend}
                                            size='large'
                                            className='w-full font-nunito bg-[#6c5dd3] text-white my-2'
                                        >
                                            Send {data?.short_name}
                                        </Button>
                                    </div>
                                </>
                            )}


                        </>
                    )}

                    {buttonManage.exchange &&
                        <div className='mt-4'>
                            <Link to='/user/swapcrypto'>
                                <Button size='large' className='w-full font-nunito bg-[#6c5dd3] text-white' >Exchange</Button>
                            </Link>
                        </div>

                    }
                </div>
            </div>
            <div className='md:col-span-2 col-span-3 h-fit rounded-md p-4 dark:bg-dark dark:border dark:border-[#334155] bg-theme-white'>
                <div className='flex justify-between items-center'>
                    <p className='text-lg font-semibold font-nunito dark:text-white'>Latest Transactions</p>
                    <Link className='bg-dark border-0 text-white' to='/user/transaction'><Button className='text-white border-0'>View All</Button></Link>
                </div>
                {transaction ? Array.isArray(transaction) && transaction?.slice(0, 3)?.map(item => {
                    return (item?.asset?.id === data?.id &&
                        <div className="w-full h-full" key={item?.id}>
                            <div className="flex items-center h-full my-2">
                                <div className="arrow me-3 p-3 border-[1px] border-gray-200 dark:border dark:border-[#334155]" style={{ boxShadow: '0px 12px 24px 0px #8F95B226', borderRadius: '10px' }}>
                                    <img style={{ width: '14px' }} src={image} alt={image} />
                                </div>
                                <div className="text-content">
                                    <h2 className='font-nunito'><span className="dark:text-white" style={{ fontWeight: '900' }}>{item?.asset?.name}</span> <span className="dark:text-dark-text">({item?.status})</span></h2>
                                    <p className='font-nunito dark:text-dark-text'>{item?.amount}</p>
                                </div>
                            </div>
                        </div>
                    )
                }) :
                    <div className="w-full flex justify-center my-4">
                        <Spin />
                    </div>
                }
            </div>
        </div >
    )
}

export default AssestsManage