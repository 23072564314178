import React, { useEffect, useState } from 'react'
import { BiSearch, BiSolidUser } from 'react-icons/bi'
import { FaWallet } from 'react-icons/fa'
import { AiFillSave } from 'react-icons/ai'
import save from '../../../assests/icons/ion_save.png'
import { IoLockClosedOutline } from 'react-icons/io5'
import { Select, Switch, Input, Button, InputNumber, Spin } from 'antd';
import image from '../../../assests/icons/agentimage.png'
import { changeHeader } from '../../../redux/reducer/headerSlice';
import { useDispatch } from 'react-redux';
import handleGet from '../../../utils/Get'
import { BASE_URL } from '../../../utils/config'
import handlePost from '../../../utils/Post'
import { useTranslation } from 'react-i18next'

const AgencyManagement = () => {

    const [tabManage, setTabManage] = useState(true);
    const [buttonManage, setButtonManage] = useState(true)
    const [loading, setLoading] = useState({ main: true, general: false, agent: false });
    const [users, setUsers] = useState('');
    const [accountData, setAccountData] = useState({ acount_holder_name: '', bank: 'swift', bank_name: '', account_number: '', require_kyc: 1 })
    const [selectUser, setSelectUser] = useState('');
    const [agents, setAgents] = useState('');
    const { t } = useTranslation();
    const { UsersManagement, General, ManageAgents, Settings, Edit, Bank, Agency, Save, BSB, Swift, AccountHolder, BankName, BankAccount, Require, Wheather, Enabled, RequireKyc, ManageUsers, NoAgents, AddAgents, AgencyBank } = t("AgencyManagement", { returnObjects: true });


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeHeader(UsersManagement));
        fetchUsers('');
        fetchAgentData();
        handleGetAgent();
    }, [UsersManagement])

    const fetchUsers = async (value) => {
        const users = await handleGet(`${BASE_URL}get/all/users?search=${value}`);
        setUsers(users?.users);
    }

    const fetchAgentData = async () => {
        try {
            const response = await handleGet(`${BASE_URL}agency/get/general-data`);
            setAccountData(response?.agency_data)
            setLoading({ ...loading, main: false });
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleGetAgent = async () => {
        try {
            const response = await handleGet(`${BASE_URL}agency/agents/all`);
            setAgents(response?.agents);
        }
        catch (error) {
            console.log(error)
        }
    }

    const onChange = (value) => {
        setSelectUser(value);
    };
    const onSearch = (value) => {
        fetchUsers(value)
    };

    const handleAddAgent = async () => {
        setLoading({ ...loading, agent: true });
        try {
            const response = await handlePost(`${BASE_URL}agency/add/agent`, { user_id: selectUser })
            setSelectUser('');
            handleGetAgent();
            setLoading({ ...loading, agent: false });
        }
        catch (error) {
            console.log(error)
            setLoading({ ...loading, agent: false });
        }
    }

    const handleUpdateAgent = async () => {
        setLoading({ ...loading, general: true });
        try {
            const response = await handlePost(`${BASE_URL}agency/update/general-data`, accountData)
            console.log(response);
            setLoading({ ...loading, general: false });
        }
        catch (error) {
            setLoading({ ...loading, general: false });
            console.log(error)
        }
    }

    return (
        loading?.main
            ?
            <div className='w-full h-[60vh] flex items-center justify-center'>
                <Spin size='large' />
            </div>
            :
            <div className='grid grid-cols-10'>
                <div className='lg:col-span-4 col-span-10'>
                    <div onClick={() => setTabManage(true)} className={`${tabManage ? 'bg-[#6C5DD3]' : ''} w-44 h-10 flex items-center gap-2 px-3 rounded-md cursor-pointer`}>
                        <p className={`${tabManage ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><BiSolidUser /></p>
                        <p className={`${tabManage ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base `}>{General}</p>
                    </div>
                    <div onClick={() => setTabManage(false)} className={`${!tabManage ? 'bg-[#6C5DD3]' : ''} w-44 h-10 flex items-center gap-2 px-3 rounded-md cursor-pointer`}>
                        <p className={`${!tabManage ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><FaWallet /></p>
                        <p className={`${!tabManage ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base textnowrap `}>{ManageAgents}</p>
                    </div>
                </div>
                <div className='lg:col-span-6 col-span-10 dark:bg-dark dark:border dark:border-[#334155] bg-theme-white rounded-xl p-4 mt-6 lg:mt-0'>
                    {tabManage ?
                        <div className='flex flex-col items-center'>
                            <div className='w-full h-full'>
                                <div className='flex justify-between'>
                                    <h2 className='text-2xl font-nunito dark:text-white font-bold'>{Settings}</h2>
                                    <Button loading={loading?.general} type='default' onClick={handleUpdateAgent} className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><AiFillSave size={20} /><p>{Save}</p></Button>
                                </div>
                                <p className='text-[#6B6B6B] dark:text-dark-text font-nunito text-base font-medium leading-3'>{Edit}</p>
                                <div className='mt-12'>
                                    <h2 className='text-xl font-nunito dark:text-white font-bold'>{Bank}</h2>
                                    <p className='text-[#6B6B6B] dark:text-dark-text font-nunito text-base font-medium'>{Agency}</p>
                                </div>
                            </div>
                            <div className='mt-6 flex justify-center gap-6'>
                                <button onClick={() => { setButtonManage(true); setAccountData({ ...accountData, bank: 'swift' }) }} className={`${buttonManage ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-[#334155] dark:text-[#94a3b8]'} w-24 p-2 rounded-lg font-nunito`}>{Swift}</button>
                                <button onClick={() => { setButtonManage(false); setAccountData({ ...accountData, bank: 'bsb' }) }} className={`${!buttonManage ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-[#334155] dark:text-[#94a3b8]'} w-24 p-2 rounded-lg font-nunito`}>{BSB}</button>
                            </div>
                            <div className="w-full my-3">
                                <span className='font-nunito text-[#6B6B6B] dark:text-dark-text'>{AccountHolder}</span>
                                <Input placeholder="Swift" onChange={e => setAccountData({ ...accountData, acount_holder_name: e.target.value })} value={accountData?.acount_holder_name} size="large" />
                            </div>
                            <div className="w-full">
                                <span className='font-nunito text-[#6B6B6B] dark:text-dark-text'>{BankName}</span>
                                <Input onChange={e => setAccountData({ ...accountData, bank_name: e.target.value })} placeholder="Bank Name" value={accountData?.bank_name} size="large" />
                            </div>
                            <div className="w-full  my-3">
                                <span className='font-nunito text-[#6B6B6B] dark:text-dark-text'>{BankAccount}</span>
                                <InputNumber onChange={e => setAccountData({ ...accountData, account_number: e })} className='w-full' type='number' placeholder="Bank Account Number" value={accountData?.account_number} size="large" />
                            </div>
                            <div className='w-full mt-4'>
                                <div>
                                    <p className='font-nunito text-lg dark:text-white font-semibold'>{Require}</p>
                                    <p className='font-nunito text-base dark:text-dark-text text-[#6B6B6B]'>{Wheather}</p>
                                    <div className='h-12 items-center gap-3 flex my-6'>
                                        <Switch checked={accountData?.require_kyc} onChange={e => setAccountData({ ...accountData, require_kyc: e === true ? 1 : 0 })} className='bg-[#bdbdbd]' />
                                        <div>
                                            <p className='font-nunito text-lg dark:text-white font-semibold'>{Enabled}</p>
                                            <p className='font-nunito text-base dark:text-dark-text text-[#6B6B6B] leading-5'>{RequireKyc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div>
                            <div>
                                <h2 className='dark:text-white text-2xl font-nunito font-bold'>{ManageUsers}</h2>
                            </div>
                            <div className='flex justify-between gap-2 mt-10 items-center'>
                                <Select
                                    size='large'
                                    className='w-full'
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="label"
                                    onChange={onChange}
                                    onSearch={onSearch}
                                    filterOption={false}
                                // value={selectUser}
                                >
                                    {users && users?.map(item => {
                                        return (
                                            <Select.Option key={item?.id} value={item?.id}>
                                                <p className='font-nunito text-lg font-medium capitalize'>{item?.name}</p>
                                            </Select.Option>
                                        )
                                    })

                                    }
                                </Select>
                                <Button loading={loading?.agent} onClick={handleAddAgent} className='bg-[#6C5DD3] hidden md:block w-44 h-10 rounded-lg font-nunito text-white'>{AddAgents}</Button>
                            </div>
                            <div>
                                <Button loading={loading?.agent} onClick={handleAddAgent} className='bg-[#6C5DD3] md:hidden mt-4 w-full h-12 rounded-lg font-nunito text-white'>{AddAgents}</Button>
                            </div>
                            <div className='my-4'>
                                {Array.isArray(agents) && agents?.length ? agents?.map(item => {
                                    return (
                                        <div key={item?.id} className='w-full h-20 dark:border dark:border-[#334155] dark:bg-[#141f38] bg-hover rounded-lg flex items-center p-2 gap-2'>
                                            <div className='w-14 h-14 rounded-full dark:border dark:border-[#334155] dark:bg-dark bg-slate-300'></div>
                                            <div>
                                                <p className='font-nunito dark:text-white text-base leading-3 font-medium'>{item?.name}</p>
                                                <p className='font-nunito dark:text-dark-text leading-4 text-xs font-bold'>{item?.email}</p>
                                            </div>
                                        </div>
                                    )
                                }) :
                                    <div className='w-full mt-8 flex flex-col items-center'>
                                        <img className='w-full max-w-sm' src={image} alt="image" />
                                        <h2 className='text-2xl dark:text-white font-nunito font-bold mt-4'>{NoAgents}</h2>
                                        <p className='text-[#6B6B6B] font-nunito text-base font-medium'>{AgencyBank}</p>
                                    </div>
                                }
                            </div>

                        </div>}
                </div>
            </div >
    )
}

export default AgencyManagement