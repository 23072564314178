import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router'
import SideBar from '../../layout/sidebar'
import Header from '../../layout/header'

const Admin = () => {

    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');
    const [showSiderbar, setShowSiderbar] = useState(false);
    const user = JSON.parse(userData);
    const navigate = useNavigate()

    useEffect(() => {
        if (!token) {
            navigate('/login');
            return;
        }
        else if (user.type === 'user') {
            navigate('/login');
        }
    }, [])


    return (token && (user.type === 'admin' || user?.type === 'agent') &&
        <div className='grid grid-cols-4 bg-admin dark:bg-dark-body h-fit min-h-screen' >
            <aside className={`${showSiderbar ? 'translate-x-0' : '-translate-x-full'} fixed xl:translate-x-0 w-72 z-10 h-[100vh] overflow-hidden transition-all duration-500`}>
                <SideBar setShowSiderbar={setShowSiderbar} />
            </aside>
            <main className='col-span-4 xl:ml-[288px] ml-0 transition-all duration-500 p-8 md:px-12 overflow-x-hidden overflow-y-auto'>
                <section className='w-full h-16 items-center flex mb-5'>
                    <Header setShowSiderbar={setShowSiderbar} />
                </section>
                <section>
                    <Outlet />
                </section>
            </main>
        </div>
    )
}

export default Admin