import React from 'react';
import img from '../assets/wallet-img.webp';
import gif from '../assets/gif2.gif';

function About() {
    return (
        <div className="container-fluid mx-auto px-4 py-10 rounded-lg mt-5">
            <div className="flex flex-col md:flex-row items-center mt-1- space-y-6 md:space-y-0 md:space-x-10">
                <div className="md:w-1/2">
                    <img src={img} alt="Wallet" className="w-full h-auto rounded-lg shadow-xl transition-transform transform hover:scale-105" />
                </div>
                <div className="md:w-1/2 md:pl-10">
                    <h1 className="text-5xl font-bold text-gray-800 mb-4  md:text-left">The Trade Market</h1>
                    <p className="text-lg text-gray-400 mb-6 md:text-left">
                        Deposit and withdraw euros using your IBAN account. Send, receive, and swap cash effortlessly.
                    </p>
                    <div className="flex justify-center md:justify-start">
                        <button className="bg-blue-600 text-white font-semibold p-2 rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105">
                            Explore Now
                        </button>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row items-center mt-14 space-y-6 md:space-y-0 md:space-x-10">
                <div className="md:w-1/2 md:pl-10">
                    <div className="content">
                        <h1 className="text-4xl font-bold text-gray-800 mb-4  md:text-left">SEND & RECEIVE CRYPTO & CASH</h1>
                        <p className="text-lg text-gray-700 mb-6  md:text-left">
                            All you need is a mobile number or QR code to send crypto and EUR. The transaction is free when the sender and the receiver use the same currency.
                        </p>
                        <div className="flex justify-center md:justify-start">
                            <button className="bg-blue-600 text-white font-semibold p-2 rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105">
                                Explore Now
                            </button>
                        </div>
                    </div>
                </div>
                <div className="md:w-1/2">
                    <img src={gif} alt="Wallet" className="w-full h-auto rounded-lg shadow-xl transition-transform transform hover:scale-105" />
                </div>
            </div>

            <div className="flex flex-col md:flex-row items-center mt-14 space-y-6 md:space-y-0 md:space-x-10">
                <div className="md:w-1/2">
                    <img src={gif} alt="Wallet" className="w-full h-auto rounded-lg shadow-xl transition-transform transform hover:scale-105" />
                </div>
                <div className="md:w-1/2 md:pl-10">
                    <div className="content">
                        <h1 className="text-4xl font-bold text-gray-800 mb-4  md:text-left">CONVERT FUNDS</h1>
                        <p className="text-lg text-gray-700 mb-6  md:text-left">
                            Seamlessly convert crypto to crypto or crypto to EUR and vice versa. Choose from a wide range of cryptocurrencies.
                        </p>
                        <div className="flex justify-center md:justify-start">
                            <button className="bg-blue-600 text-white font-semibold p-2 rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105">
                                Explore Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row items-center mt-14 space-y-6 md:space-y-0 md:space-x-10">
                <div className="md:w-1/2 md:pl-10">
                    <h1 className="text-4xl font-bold text-gray-800 mb-4  md:text-left">EASY SWAP</h1>
                    <p className="text-lg text-gray-700 mb-6  md:text-left">
                        Automatically receive payments in the crypto of your choice with our Easy Swap Engine. Secure and effortless to set up.
                    </p>
                    <div className="flex justify-center md:justify-start">
                        <button className="bg-blue-600 text-white font-semibold p-2 rounded-full hover:bg-blue-700 transition duration-300 transform hover:scale-105">
                            Explore Now
                        </button>
                    </div>
                </div>
                <div className="md:w-1/2">
                    <img src={img} alt="Wallet" className="w-full h-auto rounded-lg shadow-xl transition-transform transform hover:scale-105" />
                </div>
            </div>
        </div>
    );
}

export default About;
