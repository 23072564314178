import React, { useEffect, useState } from 'react';
import styles from '../auth.module.css'
import LoginImage from '../../assests/icons/Group.png';
import Logo from '../../assests/icons/Group.png';
import Logo_1 from '../../assests/images/new_logo.jpg';
import { Checkbox, Divider, Input, notification, Steps } from 'antd';
import { IoMailOutline } from "react-icons/io5";
import { IoLockClosedOutline } from "react-icons/io5";
import Google_logo from '../../assests/images/google.png'
import LinkedIn_logo from '../../assests/images/linkedIn.png'
import Twitter_logo from '../../assests/images/teitter.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';
import bitcoin from '../../assests/icons/bit.jpg'
import nftgif from '../../assests/images/nft.gif'
import OtpInput from 'react-otp-input';


export default function Login() {
    const [userData, setUserData] = useState({ email: '', password: '', otp: '' });
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userDataLocal = localStorage.getItem('user');
    const timeStamp = localStorage.getItem('timestamp');
    const user = JSON.parse(userDataLocal);
    const [otp, setOtp] = useState('')
    const [current, setCurrent] = useState(0);

  

    const handleLogin = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}login`, { ...userData, otp });
                if (response?.data) {
                    const token = response?.data?.authorization?.token;
                    const user = response?.data?.user;
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('timestamp', Date.now() + 3600 * 1000);
                    localStorage.setItem('time', Date.now());
                    notification.success({
                        message: 'Login successful'
                    })
                    if (user.type === 'admin' || user?.type === 'agent') {
                        navigate('/admin')
                    }
                    else {
                        navigate('/user')
                    }
                    return setUserData({ email: '', password: '', otp: '' })
                }
            }
            catch (error) {
                setCurrent(current - 1);
                setLoading(false)
                console.log(error)
                if (error?.response && error.response.data && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    if (errors?.email) {
                        notification.error({
                            message: errors?.email[0]
                        })
                        return;
                    }
                    else if (errors?.password) {
                        notification.error({
                            message: errors?.password[0]
                        })
                        return;
                    }
                }
                else if (error?.response?.data?.message === 'User Banned') {
                    notification.error({ message: error?.response?.data?.message })
                }
                else {
                    notification.error({
                        message: error?.response?.data?.message,
                    });
                }
            }
        }
    }


    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(30);

    const handleResendOtp = async  (e) => {

        e.preventDefault();
        setLoading(true);
        setIsDisabled(true);
        setTimer(30); 
        try {
            const Verify = {
                email: userData?.email,
            };
            const response = await axios.post(`${BASE_URL}send-otp`, Verify);
            if (response?.data) {
                setLoading(false);
                notification.success({
                    message: response?.data?.message,
                });
                return;
            }
        } catch (error) {
            setLoading(false);
           
        }
     
    };

    useEffect(() => {
        let interval;
        if (isDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        setIsDisabled(false);
                        clearInterval(interval);
                        return 30; // Reset timer after enabling button
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isDisabled]);
    


    const handlVerOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const Verify = {
                email: userData?.email,
            };

            const response = await axios.post(`${BASE_URL}send-otp`, Verify);
            if (response?.data) {
                setLoading(false);
                if (response?.data?.data?.type === 'admin') {
                    setCurrent(current + 2);
                }
                else {
                    setCurrent(current + 1);
                }
                notification.success({
                    message: response?.data?.message,
                });
                return;
            }
        } catch (error) {
            setCurrent(current - 1);
            setLoading(false);
            notification.error({
                message: 'The email field is required.',
            });
        }
    };

    const HandlePrev = () => {
        setCurrent(current - 1);
    }

    const HandleNext = () => {
        setCurrent(current + 1);
    }


    const steps = [
        {
            title: 'Email Verification',
            content:
                <>
                    <div>
                        <label className="text-white mb-2 flex">Email address</label>
                        <Input
                            onKeyDown={(e) => e.key === "Enter" && handlVerOtp(e)}
                            placeholder="Email Address"
                            prefix={<IoMailOutline />}
                            size="large"
                            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="mt-6">
                        <button
                            disabled={loading}
                            className={`${styles.LoginButton} w-full  ${loading ? "opacity-70" : ""}`}
                            onClick={handlVerOtp}

                        >
                            Verify Otp
                        </button>
                    </div>

                </>,
        },
        {
            title: 'Otp Verification',
            content:

                <>
                    <OtpInput
                        value={otp}
                        containerStyle={{
                            margin: "auto",
                            marginBottom: "20px",
                            marginTop: 32,
                            justifyContent: "center",
                        }}
                        onChange={setOtp}
                        inputStyle={{
                            width: "48px",
                            padding: "15px",
                            border: "2px solid #ACACAC",
                            borderRadius: 12
                        }}
                        numInputs={6}
                        renderSeparator={
                            <span style={{ marginInline: "5px" }}>  </span>
                        }
                        renderInput={(props) => <input {...props} />}
                    />

                    <button onClick={handleResendOtp} disabled={isDisabled}>
                        {isDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
                    </button>
                    <div className="flex justify-between mt-8 gap-3">
                            
                            <button
                                disabled={loading}
                                className={`${styles.LoginButton} w-25 ${loading ? "opacity-70" : ""}`}
                                onClick={HandlePrev}

                            >
                                Previous
                            </button>
                        {otp?.length > 5 ? <>

                            <button
                                onClick={HandleNext}
                                disabled={loading}
                                className={`${styles.LoginButton} w-25 ${loading ? "opacity-70" : ""}`}
                            >
                                Next  
                            </button>


                        </> :
                            
                            <>


                                <button
                                    className={`${styles.LoginButton} w-25 opacity-40 cursor-none `}
                                >
                                    Next
                                </button>


                        </>}



                    </div>

                </>,
        },
        {
            title: 'Login',
            content:

                <>
                    {/* Form */}
                    <div className="w-full max-w-full">
                        <div>
                            <label className="block text-sm font-medium text-gray-400 mt-2 mb-2">Password</label>
                            <Input.Password  onKeyDown={(e) => e.key === 'Enter' && handleLogin()} placeholder="input password" onChange={(e) => setUserData({ ...userData, password: e.target.value })} prefix={<IoLockClosedOutline />} size="large" />
                        </div>
                        <div className="col-md-12 mt-3 mb-1">
                            <button disabled={loading} onClick={handleLogin} className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}>Sign in</button>
                        </div>
                        <div className="col-md-6  mt-3">
                            <Link to='/forgot-password' style={{ color: '#8F95B2', fontWeight: '600', borderBottom: '1px solid #8F95B2', marginLeft: '5px', cursor: 'pointer', fontSize: '13px', textDecoration: 'none' }}>Forgot your password?</Link>
                        </div>
                    </div>

                   
                </>,
        },
    ];



    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    const contentStyle = {
        marginTop: 46,
        color: '#94a3b8'
    };

    useEffect(() => {
        if (timeStamp < Date.now()) {
            localStorage.removeItem('token')
            localStorage.removeItem('timestamp')
            const user = JSON.parse(localStorage.getItem('user'));
            setUserData({ email: user?.email, password: '' })
            return navigate('/login');
        }
        if (token && user) {
            if (user?.type === 'admin' || user?.type === 'agent') {
                navigate('/admin');
            }
            else if (user?.type === 'user') {
                navigate('/user')
            }

        }

    }, [])





    return (!token &&

        <div className="min-h-screen flex">
            {/* Left Section */}
            <div className="hidden lg:flex flex-col justify-center items-center bg-gradient-to-br from-[#1d1f3f] to-[#18203d] w-1/2 p-10">
                <div>

                    <h1 className="text-4xl font-bold text-white">Welcome to The Trade Market</h1>
                    <p className="text-lg text-gray-300 mt-4">The all-in-one solution for your cryptocurrency wallet, wallet management, and wallet tracking needs.</p>
                </div>
                <div className="mt-10">

                    <img src={nftgif} alt="Crypto Character" className="w-64 h-64 object-cover shadow-lg rounded-md" />
                </div>
            </div>

            {/* Right Section */}
            <div className="flex flex-col justify-center   bg-[#12122d] w-full lg:w-1/2 p-10">
                <h2 className="text-3xl text-start font-semibold text-white mb-3" style={{ textTransform: 'capitalize' }}>Log in to your account</h2>
                {/* Logo and Title */}
                <div className="mb-8">
                    <span className='text-white me-2'>Not registered yet?<Link to='/signup' style={{ color: '#8F95B2', fontWeight: '600', borderBottom: '1px solid #8F95B2', marginLeft: '5px', cursor: 'pointer', fontSize: '16px', textDecoration: 'none' }}>Register</Link></span>
                </div>

                <div className='mt-10'>
                    <Steps current={current} items={items} />
                    <div style={contentStyle}>{steps[current]?.content}</div>
                </div>


            </div>
        </div>

    )
}