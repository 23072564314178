import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        UserUpdate: (state, action) => {
            state.value = action.payload
            localStorage.setItem('user', JSON.stringify(state.value));
            console.log(state.value);
        }
    }
})

export const { UserUpdate } = userSlice.actions;
export default userSlice.reducer;