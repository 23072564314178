import React, { useEffect, useRef, useState } from 'react';
import { BiHistory, BiSolidPencil, BiSolidUser } from 'react-icons/bi';
import { AiFillLock } from 'react-icons/ai';
import save from '../../../assests/icons/ion_save.png';
import { CiMobile3 } from 'react-icons/ci';
import { CiMail } from 'react-icons/ci';
import { GoLocation } from 'react-icons/go';
import { BiSolidCity } from 'react-icons/bi';
import { PiWalletBold } from 'react-icons/pi';
import { AiFillSetting, AiFillSave } from 'react-icons/ai'
import { Button, DatePicker, Input, InputNumber, Popover, Radio, Spin, notification } from 'antd';
import profile from '../../../assests/icons/Oval.png';
import handleGet from '../../../utils/Get';
import { BASE_URL } from '../../../utils/config'
import handlePost from '../../../utils/Post';
import { RxCross1 } from 'react-icons/rx'
import { PiBankDuotone } from 'react-icons/pi'
import { BsDash, BsPencil } from 'react-icons/bs'
import { GoCreditCard } from 'react-icons/go'
import { Link } from 'react-router-dom';
import { IoLockClosedOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeader } from '../../../redux/reducer/headerSlice';
import { UserUpdate } from '../../../redux/reducer/userSlice';
import payment from '../../../assests/images/payment_image.png'
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';



const { TextArea } = Input;

const UserProfile = () => {
    const avatar1 = `${window.location.origin}/avatar1.png`
    const avatar2 = `${window.location.origin}/avatar2.jpg`
    const avatar3 = `${window.location.origin}/avatar3.jpg`
    const avatar4 = `${window.location.origin}/avatar4.png`
    const avatar5 = `${window.location.origin}/avatar5.jpg`
    const avatar6 = `${window.location.origin}/avater6.png`
    const [tabManage, setTabManage] = useState({ first: true, second: false, third: false, fourth: false });
    const [loading, setLoading] = useState({ general: false, payment: false, password: false })
    const [data, setData] = useState('')
    const [buttonManage, setButtonManage] = useState(true);
    const [radio, setRadio] = useState('bank');
    const [cardData, setCardData] = useState({ card_holder_name: '', card_number: '', card_expiry_month: dayjs().format('MM'), card_expiry_year: dayjs().format('YYYY'), card_cvv: '' })
    const [accountData, setAccountData] = useState({ acount_holder_name: '', bank_name: '', account_number: '', bank: 'swift' })
    const [password, setPassword] = useState({ current_password: '', new_password: '', repeat_password: '' })
    const [paymentMethod, setPaymentMethod] = useState('')
    const user = JSON.parse(localStorage.getItem('user'));
    const { id } = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const userData = useSelector(state => state?.user?.value);




    const defaultAvatars = [
        {
            link: avatar1,
            id: 0
        },
        {
            link: avatar2,
            id: 1
        },
        {
            link: avatar3,
            id: 2
        },
        {
            link: avatar4,
            id: 3
        },
        {
            link: avatar5,
            id: 4
        },
        {
            link: avatar6,
            id: 5
        }

    ];

    const fetchPaymentMethod = async () => {
        try {
            const data = await handleGet(`${BASE_URL}get/user/payment-methods`)
            setPaymentMethod(data?.paymentMethods)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const fetchUser = async () => {
            const data = await handleGet(`${BASE_URL}get/user/detail/${id}`)
            if (data) {
                setData(data?.user)
                dispatch(UserUpdate(data?.user))
            }
        }
        fetchUser();
        fetchPaymentMethod();
    }, [id]);

    useEffect(() => {
        dispatch(changeHeader(`${user.name}`));
    }, [user])

    const [loginUser, setLoginUser] = useState([])

    const fetchloginUser = async () => {
        const data = await handleGet(`${BASE_URL}active/user/loginhistory/${user?.id}`);
        setLoginUser(data?.data);

    }

    useEffect(() => {

        fetchloginUser();
    }, [])

    const HandleCancel = async () => {
        setLoading(true)
        const data = await handleGet(`${BASE_URL}get/user/detail/${id}`);
        if (data) {
            setLoading(false)
            setData(data?.user);
            dispatch(UserUpdate(data?.user));
        }
    }




const handleUpdateUser = async () => {
    setLoading({ ...loading, general: true });
    try {
        const formData = {
            'name': data.name,
            'email': data.email,
            'details[phone_number]': data?.detail?.phone_number,
            'details[address]': data?.detail?.address,
            'details[city]': data?.detail?.city,
            'details[country]': data?.detail?.country,
            'details[postal_code]': data?.detail?.postal_code,
            'details[note]': data?.detail?.note,
            'avatar': data?.avatar
        }

        const response = await handlePost(`${BASE_URL}update/profile`, formData);
        if (response) {
            setData(response?.data?.user);
            const note = data?.detail?.note
            const notedata = localStorage.setItem('note', note)
            dispatch(UserUpdate(response?.data?.user))
        }
        setLoading({ ...loading, general: false });
    }
    catch (error) {
        console.log(error);
        setLoading({ ...loading, general: false });
    }

}

const handlePasswordUpdate = async () => {
    setLoading({ ...loading, password: true });
    try {
        if (password?.new_password !== password?.repeat_password) {
            return notification.error({
                message: 'Please make sure the new password and repeat new password fields match exactly'
            })
        }
        const response = await handlePost(`${BASE_URL}user/update/password`, password);
        setLoading({ ...loading, password: false });
        console.log(response);
    }
    catch (err) {
        console.log(err)
        setLoading({ ...loading, password: false });
    }
}

const handleAddPayment = async () => {
    setLoading({ ...loading, payment: true });
    try {
        if (radio === 'card') {
            console.log('add')
            if (cardData?.card_cvv && cardData?.card_expiry_month && cardData?.card_expiry_year && cardData?.card_holder_name && cardData?.card_number) {
                const response = await handlePost(`${BASE_URL}store/user/payment-method`, { ...cardData, type: radio });
                if (response) {
                    fetchPaymentMethod();
                }
            }
            else {
                notification.error({ message: 'All Fields are required' })
            }
        }
        else if (radio === 'bank') {
            if (accountData?.account_number && accountData?.acount_holder_name && accountData?.bank_name) {
                const response = await handlePost(`${BASE_URL}store/user/payment-method`, { ...accountData, type: radio });
                if (response) {
                    fetchPaymentMethod();
                }
            }
            else {
                notification.error({
                    message: 'All Fields are required'
                })
            }
        }
        setLoading({ ...loading, payment: false });
    }
    catch (error) {
        console.log(error);
        setLoading({ ...loading, payment: false });
    }
}




const { t } = useTranslation();
const { recovery, Recovery, phrase, If, Phrase, and, keep, It, In, a, safe, place, This, way, recover, lose, cancel, save, Accountverification, Passwordchange, Loginhistory, Paymentmethods, information, general, Information, General, email, address, Address, userName, pass, Password, Phone, number, Number, City, city, country, Country, postal, code, write, down, your } = t("general", { returnObjects: true });
const { login, browser, location, platform, ip, at, logout, status, history, Login, device } = t("loginhistory", { returnObjects: true });
const { change, an, improved, security, account, repeat, current, Current, enter, New, settings, Settings, Repeat } = t("passwordchange", { returnObjects: true });






return (data ?
    <div className='grid grid-cols-10 gap-3 overflow-x-hidden'>
        <div className={`col-span-10 ${tabManage.first || tabManage.fourth ? 'lg:col-span-4' : 'lg:col-span-4'}`}>
            <div onClick={() => setTabManage({ first: true, second: false, third: false, fourth: false })} className={`${tabManage.first ? 'bg-[#6C5DD3]' : ''} w-100 h-10 flex items-center gap-2 px-3 rounded-md cursor-pointer text-nowrap`}>
                <p className={`${tabManage.first ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><BiSolidUser /></p>
                <p className={`${tabManage.first ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>{General}</p>
            </div>
            <div onClick={() => setTabManage({ first: false, second: true, third: false, fourth: false })} className={`${(tabManage.second || !tabManage) ? 'bg-[#6C5DD3]' : ''} w-100 h-10 flex items-center gap-2 px-3 rounded-md cursor-pointer text-nowrap`}>
                <p className={`${(tabManage.second || !tabManage) ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><PiWalletBold /></p>
                <p className={`${(tabManage.second || !tabManage) ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>{Paymentmethods}</p>
            </div>
            <div onClick={() => setTabManage({ first: false, second: false, third: true, fourth: false })} className={`${tabManage.third ? 'bg-[#6C5DD3]' : ''} w-100 h-10 flex items-center gap-2 px-3 rounded-md  cursor-pointer text-nowrap`}>
                <p className={`${tabManage.third ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><AiFillLock /></p>
                <p className={`${tabManage.third ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>{Accountverification}</p>
            </div>
            <div onClick={() => setTabManage({ first: false, second: false, third: false, fourth: true })} className={`${tabManage.fourth ? 'bg-[#6C5DD3]' : ''} w-100 h-10 flex items-center gap-2 px-3 rounded-md cursor-pointer text-nowrap`}>
                <p className={`${tabManage.fourth ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><AiFillSetting /></p>
                <p className={`${tabManage.fourth ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>{Passwordchange}</p>
            </div>
            <div onClick={() => setTabManage({ first: false, second: false, third: false, fourth: false, fifth: true })} className={`${tabManage.fifth ? 'bg-[#6C5DD3]' : ''} w-100 h-10 flex items-center gap-2 px-3 rounded-md cursor-pointer text-nowrap`}>
                <p className={`${tabManage.fifth ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary text-lg`}><BiHistory /></p>
                <p className={`${tabManage.fifth ? 'text-white' : 'text-[#6B6B6B] dark:text-dark-text'} font-primary font-medium text-base`}>{Loginhistory}</p>
            </div>
        </div>
        <div className={`col-span-10 ${tabManage.first || tabManage.fourth ? 'lg:col-span-6' : 'lg:col-span-6'} bg-theme-white  dark:bg-dark dark:border dark:border-[#334155] rounded-xl p-3 mt-6 lg:mt-0`}>
            {
                tabManage?.first ?
                    <div className={`${tabManage.first ? 'opacity-100 w-full' : 'opacity-0'} transition-all duration-500 flex flex-col items-center`}>
                        <div className='w-full h-full'>
                            <div className='flex justify-between'>
                                <div className=''>
                                    <h2 className='text-lg dark:text-white text-nowrap md:text-2xl font-nunito font-bold'>{General} {Information}</h2>
                                </div>
                                <div className='min-[520px]:flex items-center gap-2 hidden'>
                                    <Button className='font-nunito w-24 flex justify-center items-center h-10 bg-dark-text' onClick={() => HandleCancel()}><RxCross1 /><p>{cancel}</p></Button>
                                    <Button loading={loading?.general} onClick={handleUpdateUser} className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><AiFillSave size={20} /><p>{save}</p></Button>
                                </div>
                            </div>
                            <p className='text-[#6B6B6B] dark:text-gray-400 font-nunito text-base font-medium leading-3'>Manage {your} {general} {information}</p>
                            <div className='flex gap-4 min-[520px]:hidden mt-4 mb-2 justify-center'>
                                <Button className='font-nunito w-24 flex justify-center items-center h-10' onClick={() => HandleCancel()}><RxCross1 /><p>{cancel}</p></Button>
                                <Button loading={loading?.general} onClick={handleUpdateUser} className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><AiFillSave size={20} /><p>{save}</p></Button>
                            </div>
                        </div>
                        <div className='w-full h-32 flex justify-center items-center'>

                            <div className='w-24 h-24 relative bg-hover rounded-full'>
                                <img className='w-24 h-24 rounded-full border-2 border-blue-500' src={data?.avatar_url ? data?.avatar_url : avatar1} alt="avatar" />
                                <div className='absolute bottom-2 right-1'>
                                    <Popover
                                        placement='bottom'
                                        content={() => (
                                            <div className='flex gap-2'>

                                                <label className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-300 cursor-pointer">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        className="hidden"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            setData({ ...data, avatar: file });
                                                        }}
                                                    />
                                                    <BiSolidPencil size={20} color="gray" />
                                                </label>



                                                {defaultAvatars?.map(({ link, id }) => {
                                                    return (
                                                        <img
                                                            key={id}
                                                            onClick={() => {
                                                                setData({ ...data, avatar: link });
                                                                console.log(link);
                                                            }}
                                                            className={`w-12 h-12 rounded-full ${data?.avatar === link ? 'border-2 border-blue-500 p-[2px]' : ''
                                                                }`}
                                                            src={link}
                                                            alt="avatar"
                                                        />
                                                    );
                                                })}


                                            </div>
                                        )}
                                    >
                                        <BiSolidPencil color='white' size={24} />
                                    </Popover>
                                </div>

                            </div>
                        </div>
                        <div className="w-full my-2">
                            <span className='font-nunito text-[#6B6B6B] dark:text-gray-400'>{email} {address}</span>
                            <Input readOnly name='email' onChange={e => setData({ ...data, email: e.target.value })} value={data?.email} className='mt-1' style={{ padding: '10px' }} placeholder={email} prefix={<CiMail />} size="large" />
                        </div>
                        <div className='w-full grid grid-cols-1 min-[470px]:grid-cols-2 gap-2 mb-2'>
                            <div className='w-full col-span-1'>
                                <span className='font-nunito text-[#6B6B6B] dark:text-gray-400'>{userName}</span>
                                <Input onChange={e => setData({ ...data, name: e.target.value })} placeholder={userName} value={data?.name} size="large" style={{ marginRight: '20px' , padding:'12px' }} />
                            </div>
                            <div className='w-full col-span-1'>
                                <span className='font-nunito text-[#6B6B6B] dark:text-gray-400' style={{ marginRight: '10px', display: 'inline-block' }}>{Password}</span>
                                <Input.Password prefix={<IoLockClosedOutline />} disabled onChange={e => setData({ ...data, last_name: e.target.value })} placeholder={pass} size="large" />
                            </div>
                        </div>
                        <div className="w-full mb-2">
                            <span className='font-nunito text-[#6B6B6B] dark:text-gray-400'>{Phone} {number}</span>
                            <Input type='number' name='phone_number' onChange={e => setData({ ...data, detail: { ...data?.detail, phone_number: e.target.value } })} className='mt-1' placeholder={Phone} value={data?.detail?.phone_number} prefix={<CiMobile3 />} size="large" />
                        </div>
                        <div className='w-full grid grid-cols-1 min-[470px]:grid-cols-2 gap-2 mb-2'>
                            <div className='col-span-1'>
                                <span className='font-nunito text-[#6B6B6B] dark:text-gray-400' style={{ marginRight: '0px' }}>{Address}</span>
                                <Input name='address' onChange={e => setData({ ...data, detail: { ...data?.detail, address: e.target.value } })} className='w-full' placeholder={address} prefix={<GoLocation />} value={data?.detail?.address} size="large" />
                            </div>
                            <div className='col-span-1'>
                                <span className='font-nunito text-[#6B6B6B] dark:text-gray-400' style={{ marginRight: '0px' }}>{City}</span>
                                <Input name='city' onChange={e => setData({ ...data, detail: { ...data?.detail, city: e.target.value } })} placeholder="city" prefix={<BiSolidCity />} value={data?.detail?.city} size="large" />
                            </div>
                        </div>
                        <div className='w-full grid grid-cols-1 min-[470px]:grid-cols-2 gap-2'>
                            <div className='col-span-1'>
                                <span className='font-nunito text-[#6B6B6B] dark:text-gray-400' style={{ marginRight: '0px' }}>{Country}</span>
                                <Input name='country' onChange={e => setData({ ...data, detail: { ...data?.detail, country: e.target.value } })} className='w-full' placeholder={country} value={data?.detail?.country} size="large" style={{ marginRight: '0px' }} />
                            </div>
                            <div className='col-span-1'>
                                <span className='font-nunito text-[#6B6B6B] dark:text-gray-400' style={{ marginRight: '0px' }}>{postal} {code}</span>
                                <Input name='postal_code' onChange={e => setData({ ...data, detail: { ...data?.detail, postal_code: e.target.value } })} placeholder={postal} value={data?.detail?.postal_code} size="large" />
                            </div>
                        </div>
                        <div className='mt-3 w-full'>
                            <h2 className='font-nunito dark:text-dark-text'>Notes</h2>
                            <TextArea readOnly name='note' value={data?.detail?.note} onChange={e => setData({ ...data, detail: { ...data?.detail, note: e.target.value } })} className='mt-1' rows={4} />
                        </div>

                    </div>
                    :
                    tabManage?.second ?
                        <div className={`${tabManage.second ? 'opacity-100' : 'opacity-0'} transition-all duration-500 flex flex-col items-center`}>
                            <div className='w-full h-full'>
                                <div className='flex justify-between items-center'>
                                    <h2 className='text-2xl dark:text-white font-nunito font-bold text-nowrap'>Payment Methods</h2>
                                    <button onClick={() => setTabManage(false)} className='hidden w-fit px-2 text-sm h-10 min-[520px]:flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><img src={save} alt="save" /><p>Add New Payment Method</p></button>
                                </div>
                                <p className='text-[#6B6B6B] dark:text-gray-400 font-nunito text-base font-medium leading-3'>Manage your payment methods</p>
                                <div className='w-full flex justify-center mt-4 min-[520px]:hidden'>
                                    <button onClick={() => setTabManage(false)} className='w-full px-2 text-sm h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><img src={save} alt="save" /><p>Add New Payment Method</p></button>
                                </div>
                            </div>
                            {
                                <div className='w-full mt-4'>
                                    {paymentMethod.length ? paymentMethod?.map(item => {
                                        return (
                                            <div key={item?.id} className='w-full h-20 border border-gray-500 rounded-lg my-2 p-2 flex items-center gap-2'>
                                                <div className='h-14 w-14 border border-gray-400 rounded-full flex justify-center items-center'>
                                                    {item?.type === 'card' ? <GoCreditCard size={24} color='gray' /> : <PiBankDuotone size={24} color='gray' />}
                                                </div>
                                                <p className='font-nunito'>{`${item?.type === 'card' ? item?.card_holder_name : item?.acount_holder_name} ${item?.type === 'card' ? `*****${item?.card_number?.slice(0, 4)}` : `*******${item?.account_number?.slice(0, 4)}`} `}</p>
                                            </div>
                                        )
                                    })

                                        :
                                        <div className="w-full flex flex-col mt-20 items-center">
                                            <img className='w-full h-64 max-w-[360px] ' src={payment} alt="payment" />
                                            <p className='text-lg dark:text-white font-bold mt-8 font-nunito'>No payments method found</p>
                                            <p className='font-nunito text-sm text-gray-400 mb-8'>Try to add a new payment</p>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        :
                        tabManage.third ?
                            <div className='flex flex-col items-center'>
                                <div className='w-full h-full'>
                                    <div className='flex justify-between items-center'>
                                        <h2 className='text-2xl font-nunito font-bold my-2 dark:text-white'>Payment Methods</h2>
                                    </div>
                                    <p className='text-[#6B6B6B] dark:text-gray-400 font-nunito text-base font-medium leading-3'>Manage your payment methods</p>
                                </div>
                                <div className='w-full my-4 relative'>
                                    <div className='w-full h-full border-[1px] border-opacity-60 dark:border-[#334155] border-gray-400 rounded-lg'>
                                        <div className='w-full h-full p-4 flex items-center gap-2 flex-wrap justify-between'>
                                            <div className='w-full h-full flex items-center gap-2 justify-between flex-wrap'>
                                                <div className='w-full max-w-fit h-full flex items-center gap-2'>
                                                    <img className='w-24 h-24 rounded-full border-2 border-blue-500' src={data?.avatar_url ? data?.avatar_url : avatar1} alt="avatar" />
                                                    <div className=''>
                                                        <p className='font-nunito w-fit font-bold dark:text-white text-[#000] leading-3'>{data.name}</p>
                                                        <p className='font-nunito w-full max-w-[262px] dark:text-gray-400 text-[#6C5DD3]'>{`${data.email} `}</p>
                                                    </div>
                                                </div>
                                                {(user?.active_status === 'approved') ?
                                                    <div className='w-fit h-fit bg-[#2daa2d] px-3 py-1 rounded-md text-white font-nunito'>Verified</div>
                                                    : user?.kyc_document_address ?
                                                        <div className='w-fit h-fit bg-[#2daa2d] px-3 py-1 rounded-md text-white font-nunito'>KYC Pending</div>
                                                        :
                                                        <div className='w-fit h-fit bg-[#6b6b6b] px-3 py-1 rounded-md text-white font-nunito'>Unverified</div>
                                                }
                                            </div>
                                        </div>
                                        <hr />
                                        {(user?.active_status === 'approved' || user?.kyc_document_address) ? '' :
                                            <div className='w-full h-fit p-4 flex justify-between items-center flex-wrap'>
                                                <div className='flex items-center  gap-3'>
                                                    <p className='font-nunito text-nowrap text-gray-500'>KYC Progress</p>
                                                    <p className='text-sm text-gray-500 text-nowrap font-nunito'>Waiting for approval</p>
                                                </div>
                                                <Link to='/verification' className='text-xs font-nunito border-2 border-gray-300 rounded-lg py-2 px-4 bg-dark text-white'>Start Verificaton</Link>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            : tabManage.fourth ?
                                <div className='flex flex-col items-center'>
                                    <div className='w-full h-full'>
                                        <div className='flex justify-between'>
                                            <div>
                                                <h2 className='text-2xl font-nunito font-bold dark:text-white'>Settings</h2>
                                            </div>
                                            <div className='hidden min-[520px]:flex items-center gap-2'>
                                                <Button className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito bg-dark-text' onClick={() => HandleCancel()}><RxCross1 /><p>Cancel</p></Button>
                                                <Button loading={loading?.password} onClick={handlePasswordUpdate} className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><AiFillSave size={20} /><p>Save</p></Button>
                                            </div>
                                        </div>
                                        <p className='text-[#6B6B6B] font-nunito text-base font-medium leading-3 dark:text-gray-400'>Manage {your} account {settings}</p>
                                        <div className='w-full flex min-[520px]:hidden justify-center items-center mt-4 gap-4'>
                                            <Button className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito' onClick={() => HandleCancel()}><RxCross1 /><p>Cancel</p></Button>
                                            <Button loading={loading?.password} onClick={handlePasswordUpdate} className='w-24 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><AiFillSave size={20} /><p>Save</p></Button>
                                        </div>
                                    </div>
                                    <p className='text-2xl font-nunito font-bold mt-3 min-[520px]:mt-10 dark:text-white'>{change} {Password}</p>
                                    <p className='text-[#6B6B6B] font-nunito text-base font-medium leading-3 mb-8 dark:text-dark-text'>for {an} {improved} {account} {security}</p>
                                    <div className='w-full my-2'>
                                        <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '10px', display: 'inline-block' }}>{current} {Password}</span>
                                        <Input.Password prefix={<IoLockClosedOutline />} onChange={e => setPassword({ ...password, current_password: e.target.value })} placeholder="neer" current password value={password?.current_password} size="large" />
                                    </div>
                                    <div className='w-full my-2'>
                                        <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '10px', display: 'inline-block' }}>{New} {Password}</span>
                                        <Input.Password prefix={<IoLockClosedOutline />} onChange={e => setPassword({ ...password, new_password: e.target.value })} placeholder="Enter new password" value={password?.new_password} size="large" />
                                    </div>
                                    <div className='w-full my-2'>
                                        <span className='font-nunito text-[#6B6B6B] dark:text-dark-text' style={{ marginRight: '10px', display: 'inline-block' }}>{Repeat} {New} {Password}</span>
                                        <Input.Password prefix={<IoLockClosedOutline />} onChange={e => setPassword({ ...password, repeat_password: e.target.value })} placeholder="Enter repeat new password" value={password?.repeat_password} size="large" />
                                    </div>
                                    {/* <div className="w-full flex my-8 justify-center gap-4">
                                        <button className='w-48 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-black bg-theme-white border-1 border-gray-500'><RxCross1 /><p>Cancel</p></button>
                                        <button onClick={handlePasswordUpdate} className='w-48 h-10 flex justify-center items-center gap-2 rounded-lg font-nunito text-white bg-[#7EBA00]'><img src={save} alt="save" /><p>Save</p></button>
                                    </div> */}
                                </div>
                                : tabManage.fifth &&
                                <div className="container mx-auto px-4 sm:px-8">
                                    <div className="mt-3">
                                        <div>
                                            <h2 className="text-2xl font-semibold leading-tight dark:text-white">{Login} {history}</h2>
                                        </div>

                                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-2 max-h-[500px] overflow-scroll mt-4">
                                            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                                <table className="min-w-full leading-normal bg-theme-white dark:bg-dark dark:border-[#334155]">
                                                    <thead>
                                                        <tr>
                                                            <th className="px-3 py-3  border-b-2 dark:bg-dark-active  text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {device}
                                                            </th>
                                                            <th className="px-3 py-3 border-b-2 dark:bg-dark-active  text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {browser}
                                                            </th>
                                                            <th className="px-3 py-3 border-b-2 dark:bg-dark-active  text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {location}
                                                            </th>
                                                            <th className="px-3 py-3 border-b-2 dark:bg-dark-active  text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {platform}
                                                            </th>
                                                            <th className="px-3 py-3 border-b-2 dark:bg-dark-active  text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {ip} {Address}
                                                            </th>
                                                            <th className="px-3 py-3 border-b-2 dark:bg-dark-active whitespace-nowrap  text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {login} {at}
                                                            </th>
                                                            <th className="px-3 py-3 border-b-2 dark:bg-dark-active whitespace-nowrap text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {logout} {at}
                                                            </th>
                                                            <th className="px-3 py-3 border-b-2 dark:bg-dark-active  text-left text-xs font-semibold dark:text-white uppercase tracking-wider">
                                                                {status}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loginUser?.map((item) => (
                                                            <tr>
                                                                <td className="px-3 py-3 border-b border-gray-200 bg-transparent text-sm">
                                                                    <div className="flex items-center">

                                                                        <div className="ml-3">
                                                                            <p className="dark:text-white whitespace-no-wrap">
                                                                                {item?.device || 'N/A'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-3 py-3 border-b border-gray-200 bg-transparent text-sm">
                                                                    <p className="dark:text-white whitespace-no-wrap"> {item?.browser || 'N/A'}</p>
                                                                </td>
                                                                <td className="px-3 py-3 border-b border-gray-200 bg-transparent text-sm">
                                                                    <p className="dark:text-white whitespace-no-wrap">
                                                                        {item?.location || 'N/A'}
                                                                    </p>
                                                                </td>
                                                                <td className="px-3 py-3 border-b border-gray-200 bg-transparent text-sm">
                                                                    <p className="dark:text-white whitespace-no-wrap">
                                                                        {item?.platform || 'N/A'}
                                                                    </p>
                                                                </td>
                                                                <td className="px-3 py-3 border-b border-gray-200 bg-transparent text-sm">
                                                                    <p className="dark:text-white whitespace-no-wrap">
                                                                        {item?.ip_address || 'N/A'}
                                                                    </p>
                                                                </td>
                                                                <td className="px-3 py-3 border-b border-gray-200 bg-transparent text-sm">
                                                                    <p className="dark:text-white whitespace-no-wrap">
                                                                        {item?.login_timestamp || 'N/A'}
                                                                    </p>
                                                                </td>
                                                                <td className="px-3 py-3 border-b border-gray-200 bg-transparent text-sm">
                                                                    <p className="dark:text-white whitespace-no-wrap">
                                                                        {item?.logout_timestamp || 'N/A'}
                                                                    </p>
                                                                </td>
                                                                <td className="px-3 py-3 border-b text-sm">
                                                                    <span className="relative inline-block px-3 py-1 font-semibold leading-tight bg-dark text-center text-white">
                                                                        {item?.status || 'N/A'}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

            }
            {!tabManage &&
                <div>
                    <div className='flex justify-between items-center'>
                        <p className='font-nunito dark:text-white'>ADD NEW PAYMENT METHOD</p>
                        <div className='hidden gap-2 min-[470px]:flex'>
                            <Button onClick={() => setTabManage({ second: true })} size='large'>Cancel</Button>
                            <Button loading={loading?.payment} onClick={handleAddPayment} size='large' className='bg-[#6c5dd3] text-white'>Save</Button>
                        </div>
                    </div>
                    <div className='flex justify-center gap-2 min-[470px]:hidden mt-4'>
                        <Button onClick={() => setTabManage({ second: true })} size='large'>Cancel</Button>
                        <Button loading={loading?.payment} onClick={handleAddPayment} size='large' className='bg-[#6c5dd3] text-white'>Save</Button>
                    </div>
                    <div className='my-2'>
                        <Radio.Group value={radio} onChange={(e) => setRadio(e.target.value)} className='w-full'>
                            <Radio value='bank' className='w-full py-2'><span className='font-nunito dark:text-gray-400 text-base'>Bank Account</span></Radio>
                            <Radio value='card' className='w-full py-2 my-2'><p className='font-nunito dark:text-gray-400 text-base'><span className='font-nunito text-base'>Credit Card</span></p></Radio>
                        </Radio.Group>
                    </div>
                    {radio === 'card' ?
                        <div className='grid grid-cols-2 gap-2'>
                            <div className='col-span-1'>
                                <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>Card Holder Name</label>
                                <Input value={cardData?.card_holder_name} onChange={e => setCardData({ ...cardData, card_holder_name: e.target.value })} size='large' placeholder='ex: John Doe' />
                                <div className='my-2'>
                                    <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>Card Number</label>
                                    <InputNumber className='w-full' type='number' value={cardData?.card_number} onChange={e => setCardData({ ...cardData, card_number: e })} size='large' placeholder='ex: 424244242442442' />
                                </div>
                                <div className='w-full grid grid-cols-3 gap-2'>
                                    <div className='col-span-1'>
                                        <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>Exp. Month</label>
                                        <DatePicker value={cardData?.card_expiry_month ? dayjs(cardData?.card_expiry_month) : dayjs()} onChange={(date, value) => setCardData({ ...cardData, card_expiry_month: value })} format='MM' allowClear={false} picker='month' suffixIcon={false} size='large' placeholder='ex: 03' />
                                    </div>
                                    <div className='col-span-1'>
                                        <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>Exp. Year</label>
                                        <DatePicker value={cardData?.card_expiry_year ? dayjs(cardData?.card_expiry_year) : dayjs()} format='YYYY' onChange={(date, value) => setCardData({ ...cardData, card_expiry_year: value })} allowClear={false} picker='year' suffixIcon={false} size='large' placeholder='ex: 03' />

                                    </div>
                                    <div className='col-span-1'>
                                        <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>CVV</label>
                                        <InputNumber onChange={e => setCardData({ ...cardData, card_cvv: e })} maxLength={3} type='number' size='large' placeholder='ex: 123' />
                                    </div>
                                </div>
                            </div>
                            <div className='grid-cols-1 h-full pt-4'>
                                <div className='w-full h-full rounded-lg p-4 border-[1px] dark:border-[#334155] border-gray-400 relative'>
                                    <div className='flex items-center justify-between'>
                                        <div className='flex gap-2 items-center'>
                                            <div className='w-2 h-2 rounded-full bg-[#e2e8f0]' />
                                            <p className='font-nunito text-sm text-[#94a3b8] dark:text-gray-400'>Mastercard</p>
                                        </div>
                                        <div className='absolute end-4 top-4 flex'>
                                            <div className='-me-2 h-9 w-9 rounded-full bg-rose-500/60' />
                                            <div className='relative z-10 -ms-2 h-9 w-9 rounded-full bg-yellow-500/60' />
                                        </div>
                                    </div>
                                    <img className='w-11 mt-4 mb-2' src='https://app.encryptwallet.io/img/illustrations/card-chip.svg' alt="" />
                                    <p className='font-nunito text-[#64748b] text-sm'>•••••• ••••••</p>
                                    <p className='font-heading text-[#8d97a7] text-xs'>•••• •••• •••• ••••</p>
                                    <div className='flex gap-2 mt-1'>
                                        <p className='font-heading text-[#8d97a7] text-xs'>EXP 02/••</p>
                                        <p className='font-heading text-[#8d97a7] text-xs'>CVC •••</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='w-full flex justify-center'>
                                <div onClick={() => { setButtonManage(true); setAccountData({ ...accountData, bank: 'swift' }) }} className={`${buttonManage ? 'bg-[#6C5DD3]' : ''} w - 28 h - 10 flex items - center gap - 2 px - 3 rounded - md justify - center`}>
                                    <p className={`${buttonManage ? 'text-white' : 'text-[#6B6B6B]'} font - primary font - medium text - base`}>Swift</p>
                                </div>
                                <div onClick={() => { setButtonManage(false); setAccountData({ ...accountData, bank: 'bsb' }) }} className={`${!buttonManage ? 'bg-[#6C5DD3]' : ''} w - 28 h - 10 flex items - center gap - 2 px - 3 rounded - md justify - center`}>
                                    <p className={`${!buttonManage ? 'text-white' : 'text-[#6B6B6B]'} font - primary font - medium text - base`}>BSB</p>
                                </div>
                            </div>
                            <form onKeyDown={e => e.key === 'Enter' && handleAddPayment()}>
                                <div>
                                    <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>Account Number</label>
                                    <InputNumber value={accountData?.account_number} onChange={e => setAccountData({ ...accountData, account_number: e })} type='number' className='w-full' size='large' placeholder='ex: 4324542415425' />
                                </div>
                                <div className='my-3'>
                                    <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>Account Holder Name</label>
                                    <Input value={accountData?.acount_holder_name} onChange={e => setAccountData({ ...accountData, acount_holder_name: e.target.value })} size='large' placeholder='ex: John Doe' />
                                </div>
                                <div>
                                    <label className='font-nunito text-[#6B6B6B] dark:text-gray-400 text-sm'>Bank Name</label>
                                    <Input value={accountData?.bank_name} onChange={e => setAccountData({ ...accountData, bank_name: e.target.value })} size='large' placeholder='ex: John Doe' />
                                </div>
                            </form>
                        </div>
                    }
                </div>

            }
        </div>
    </div > :
    <div className='w-full my-24 flex justify-center items-center'>
        <Spin size='large' />
    </div>
);
}

export default UserProfile;