import React, { useEffect, useState } from 'react';
import styles from '../auth.module.css'
import Logo from '../../assests/images/new_logo.jpg';
import { Checkbox, Divider, Input } from 'antd';
import { IoMailOutline, IoPersonOutline, IoLockClosedOutline } from "react-icons/io5";
import Google_logo from '../../assests/images/google.png'
import LinkedIn_logo from '../../assests/images/linkedIn.png'
import Twitter_logo from '../../assests/images/teitter.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { notification } from 'antd';
import { BASE_URL } from '../../utils/config';
import nftgif from '../../assests/images/nft.gif';
import { Button, message, Steps, theme } from 'antd';
import OtpInput from 'react-otp-input';


function SignUp() {

    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('')
    const [checkedValue, setchecked] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (checkedValue === true) {
            try {
                const registrationData = {
                    name: userName,
                    email: userEmail,
                    password: userPassword,
                    otp: otp,
                    type: 'user'
                };

                const response = await axios.post(`${BASE_URL}register`, registrationData);
                if (response?.data) {
                    setLoading(false);
                    console.log('Registration success:', response.data);
                    const token = response.data.authorisation.token;
                    const user = response?.data?.user;
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('token', token);
                    localStorage.setItem('timestamp', Date.now() + 3600 * 1000);
                    localStorage.setItem('time', Date.now());
                    notification.success({
                        message: response?.data?.message,
                        // placement: 'topLeft', // Position of the notification
                    });
                    if (user.type === 'admin') {
                        navigate('/admin');
                    }
                    else {
                        navigate('/user');
                    }
                    return;
                }
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.data && error.response.data.errors) {
                    setCurrent(current - 1);
                    setOtp('')
                    const errors = error.response.data.errors;
                    if (errors.name) {
                        notification.error({
                            message: errors.name[0],
                        });
                        return;
                    }
                    if (errors.email) {
                        notification.error({
                            message: errors.email[0],
                        });
                        return;
                    }
                    if (errors.password) {
                        notification.error({
                            message: errors.password[0],
                        })
                        return;
                    }
                } else {
                    setCurrent(current - 1);
                    setOtp('')
                    console.error('Registration error:', error);
                    notification.error({
                        message: 'An error occurred during registration.',
                    });
                }
            }
        } else {
            message.error('Please agree to our terms and conditions.')

        }

    };


    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(30);

    const handleResendOtp = async (e) => {

        e.preventDefault();
        setLoading(true);
        setIsDisabled(true);
        setTimer(30);
        try {
            const Verify = {
                email: userData?.email,
            };
            const response = await axios.post(`${BASE_URL}send-otp`, Verify);
            if (response?.data) {
                setLoading(false);
                notification.success({
                    message: response?.data?.message,
                });
                return;
            }
        } catch (error) {
            setLoading(false);

        }

    };

    useEffect(() => {
        let interval;
        if (isDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        setIsDisabled(false);
                        clearInterval(interval);
                        return 30;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isDisabled]);

    const handlVerOtp = async (e) => {
        if (userEmail === 'admin97@gmail.com') {
            notification.info({
                message: 'Cannot be registered as admin',
            });
        }
        else {
            e.preventDefault();
            setLoading(true);
            try {
                const Verify = {
                    email: userEmail,
                };

                const response = await axios.post(`${BASE_URL}send-otp`, Verify);
                if (response?.data) {
                    setLoading(false);
                    setCurrent(current + 1);

                    notification.success({
                        message: response?.data?.message,
                    });
                    return;
                }
            } catch (error) {
                setLoading(false);
                notification.error({
                    message: 'The email field is required.',
                });
            }
        }

    };

    const HandlePrev = () => {
        setCurrent(current - 1);
    }


    const HandleNext = () => {
        setCurrent(current + 1);
    }

    useEffect(() => {
        if (token && user) {
            if (user.type === 'admin') {
                navigate('/admin');
            }
            else if (user?.type === 'user') {
                navigate('/user')
            }
        }

    }, [])


    const checked = () => {
        setchecked(true)
    }


    const steps = [
        {
            title: 'Email Verification',
            content:
                <>
                    <div>
                        <label className="text-white mb-2 flex">Email address</label>
                        <Input
                            type='email'
                            onKeyDown={(e) => e.key === "Enter" && handlVerOtp(e)}
                            placeholder="Email Address"
                            prefix={<IoMailOutline />}
                            size="large"
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="mt-6">
                        <button
                            disabled={loading}
                            className={`${styles.LoginButton} w-full  ${loading ? "opacity-70" : ""}`}
                            onClick={handlVerOtp}

                        >
                            Verify Otp
                        </button>
                    </div>

                </>,
        },
        {
            title: 'Otp Verification',
            content:

                <>
                    <OtpInput
                        value={otp}
                        containerStyle={{
                            margin: "auto",
                            marginBottom: "20px",
                            marginTop: 32,
                            justifyContent: "center",
                        }}
                        onChange={setOtp}
                        inputStyle={{
                            width: "48px",
                            padding: "15px",
                            border: "2px solid #ACACAC",
                            borderRadius: 12
                        }}
                        numInputs={6}
                        renderSeparator={
                            <span style={{ marginInline: "5px" }}>  </span>
                        }
                        renderInput={(props) => <input {...props} />}
                    />
                    <button onClick={handleResendOtp} disabled={isDisabled}>
                        {isDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
                    </button>

                    <div className="flex justify-between mt-8 gap-3">
                        <button
                            disabled={loading}
                            className={`${styles.LoginButton} w-25 ${loading ? "opacity-70" : ""}`}
                            onClick={HandlePrev}

                        >
                            Previous
                        </button>
                        {otp?.length > 5 ? <>

                            <button
                                onClick={HandleNext}
                                disabled={loading}
                                className={`${styles.LoginButton} w-25 ${loading ? "opacity-70" : ""}`}
                            >
                                Next
                            </button>


                        </> :

                            <>


                                <button
                                    className={`${styles.LoginButton} w-25 opacity-40 cursor-none `}
                                >
                                    Next
                                </button>


                            </>}



                    </div>

                </>,
        },
        {
            title: 'Register',
            content:

                <>
                    {/* Form Fields */}
                    <div className="space-y-4">
                        <div>
                            <label className="text-white mb-2 flex">Name</label>
                            <Input
                                onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
                                placeholder="Jane Eyre"
                                prefix={<IoPersonOutline />}
                                size="large"
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>

                        <div>
                            <label className="text-white mb-2 flex">Password</label>
                            <Input.Password
                                onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
                                placeholder="Min. 8 characters"
                                prefix={<IoLockClosedOutline />}
                                size="large"
                                onChange={(e) => setUserPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Terms & Conditions */}
                    <div className="mt-4">
                        <Checkbox onClick={checked} className="text-[#8F95B2]">
                            I agree to the <span>Terms & Condition</span>
                        </Checkbox>
                    </div>

                    {/* Submit Button */}

                    <div className="mt-6">
                        <button
                            className={`${styles.LoginButton} w-full`}
                            onClick={handleSubmit}
                        >
                            Sign Up
                        </button>
                    </div>


                </>,
        },
    ];

    const [current, setCurrent] = useState(0);
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    const contentStyle = {
        marginTop: 46,
        color: '#94a3b8'
    };


    return (!token &&


        <div className="w-full dark:bg-dark">
            <div className="w-full h-screen grid grid-cols-1 lg:grid-cols-2">
                <div className="hidden lg:flex flex-col justify-center items-center bg-gradient-to-br from-[#1d1f3f] to-[#18203d] p-10">
                    <div>
                        <h1 className="text-4xl font-bold text-white">Welcome to The Trade Market</h1>
                        <p className="text-lg text-gray-300 mt-4">
                            The all-in-one solution for your cryptocurrency wallet, wallet management, and wallet tracking needs.
                        </p>
                    </div>
                    <div className="mt-10">
                        <img
                            src={nftgif}
                            alt="Crypto Character"
                            className="w-64 h-64 object-cover shadow-lg rounded-md"
                        />
                    </div>
                </div>

                <div className="flex flex-col justify-center bg-[#12122d] w-full p-10 ">
                    <h2 className="text-3xl text-start font-semibold text-white mb-2">Create an account</h2>
                    <div className="mb-8">
                        <span className="text-white">
                            Have an account?
                            <Link
                                to="/login"
                                className="ml-2 text-[#8F95B2] font-semibold border-b border-[#8F95B2] cursor-pointer"
                            >
                                Login
                            </Link>
                        </span>
                    </div>

                    <div className='mt-10'>
                        <Steps current={current} items={items} />
                        <div style={contentStyle}>{steps[current].content}</div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default SignUp