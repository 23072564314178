import React from 'react';
import security from '../assets/security.webp'



function SectionLast() {
    return (
        <div className="container-fluid mx-auto px-4 py-10 rounded-lg mt-10">

            <div className="flex flex-col md:flex-row items-center mt-1- space-y-6 md:space-y-0 md:space-x-10 bg-white p-5 rounded">

                <div className="md:w-1/2 md:pl-10">
                    <h1 className="text-4xl font-bold text-gray-800 mb-4  md:text-left">SECURITY & COMPLIANCE</h1>
                    <p className="text-lg text-gray-700 mb-6 md:text-left">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta amet porro blanditiis nam, laborum saepe aliquam earum provident placeat quaerat? Accusantium repellat dolorem odio nihil, delectus tenetur nostrum possimus quae.
                    </p>

                </div>

                <div className="md:w-1/2">
                    <img src={security} alt="Wallet" className="w-3/4 transition-transform transform hover:scale-105" />
                </div>
            </div>


        </div>
    );
}

export default SectionLast;
