import React from 'react';
import feature1 from '../assets/feature1.webp';
import feature2 from '../assets/feature2.webp';
import feature3 from '../assets/feature3.webp';

function Features() {
    return (
        <>
        <div className="container-fluid bg-[#EAEEFF]">
                <div className="container mx-auto px-4 mt-14  p-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="bg-white rounded-lg shadow-md border p-6 flex flex-col items-center transition-transform transform hover:scale-105">
                            <img src={feature1} alt="Free Feature" className="h-24 w-24 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Free</h3>
                            <p className="text-gray-400 text-center text-sm p-3">
                                Send crypto for free when the recipient accepts the payment in the same currency.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md  border p-6 flex flex-col items-center transition-transform transform hover:scale-105">
                            <img src={feature2} alt="Secure Feature" className="h-24 w-24 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Secure</h3>
                            <p className="text-gray-400 text-center text-sm p-3">
                                We comply with legal requirements preventing money laundering, fraud, and other illegal activities.
                            </p>
                        </div>
                        <div className="bg-white rounded-lg shadow-md border p-6 flex flex-col items-center transition-transform transform hover:scale-105">
                            <img src={feature3} alt="Fast Feature" className="h-24 w-24 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Fast</h3>
                            <p className="text-gray-400 text-center text-sm p-3">
                                Complete cross-border payments in a matter of seconds. Simple and easy to use, especially for beginners.
                            </p>
                        </div>
                    </div>
                </div>      
        </div>
        </>
        
    );
}

export default Features;
