import React, { useEffect, useState } from "react";
// import "./dashboard.css";
import chart from "../../../assests/images/chart-guy 1.png";
import ph_swap from "../../../assests/images/ph_swap.png";
import bitcoin from "../../../assests/images/Vector.png";
import { Button, InputNumber, Select, Spin, notification } from "antd";
import { BsFillGearFill } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import Logo from "../../../assests/icons/Group.png";
import ApexChart from "../../../components/chart";
import { useDispatch } from "react-redux";
import { changeHeader } from "../../../redux/reducer/headerSlice";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/config";
import handleGet from "../../../utils/Get";
import image from "../../../assests/images/Arrow 2.png";
import Logo_1 from "../../../assests/images/new_logo.jpg";
import axios from "axios";
import bit from "../../../assests/images/logo-bitcoin.svg";
import eth from "../../../assests/images/logo-eth.svg";
import handlePost from "../../../utils/Post";
import { useTranslation } from "react-i18next";

const UserDashboard = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [assets, setAssets] = useState("");
  const [transactions, setTransactions] = useState("");
  const [netWorth, setNetWorth] = useState({ value: "", loading: true });
  const [userNote, setNote] = useState({ value: "", loading: true });
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState("");
  const [graphData2, setGraphData2] = useState("");
  const { t } = useTranslation();
  const { welcomeback, chart, Assets, price, view, all, Swap, Latest, Transactions, The, Trade, Market, start, kyc, BTC, Overview, my, net, worth, exchange, crypto, select, from, asset, enter, network, fe, no, of, coins, total, For, ETH, verify, your, identify, enhanced, security, account, is, not, verified, you, can, only, use, the, basic, features, please } = t("dashboard", { returnObjects: true });


  const fetchAssets = async () => {
    const data = await handleGet(`${BASE_URL}active/user/assets`);
    setAssets(data?.assets);
  };

  const fetchTransaction = async () => {
    const data = await handleGet(`${BASE_URL}active/user/transaction`);
    setTransactions(data?.transactions);
  };

  const fetchBitcoinData = async () => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=120&interval=daily`
      );
      setGraphData(response?.data?.prices);
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchEthcoinData = async () => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=120&interval=daily`
      );
      setGraphData2(response?.data?.prices);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchNetWorth = async () => {
    try {
      const data = await handleGet(`${BASE_URL}get/user/networth`);
      setNetWorth({ value: data?.networth, loading: false });
      setNote({ value: data?.userNote, loading: false });
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    dispatch(changeHeader(`${user.name}, ${welcomeback}`));
    fetchNetWorth();
    fetchAssets();
    fetchTransaction();
    fetchBitcoinData();
    fetchEthcoinData();
  }, [welcomeback]);

  const handleNavigate = (value) => {
    navigate("/user/assets/manage", { state: value });
  };

  const [data, setData] = useState([]);
  const [fromAsset, setFromAsset] = useState("");
  const [toAsset, setToAsset] = useState("");
  const [amount, setAmount] = useState(null);
  const [exchangeData, setExchangeData] = useState("");
  const [loading, setLoading] = useState(false);
  const [swapping, setSwapping] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchAssets = async () => {
      setLoading(true)
      try {
        const response = await handleGet(`${BASE_URL}active/user/assets`);
        setData(response?.assets || []);
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAssets();
  }, []);

  const handleExchange = async (newAmount, newFromAsset, newToAsset) => {
    if (!newAmount || !newFromAsset || !newToAsset) return;

    try {
      setLoader(true);
      const response = await handlePost(
        `${BASE_URL}currencyexchange/onchange`,
        {
          amount: newAmount,
          from_asset_id: newFromAsset,
          to_asset_id: newToAsset,
        }
      );
      if (response) setExchangeData(response?.data);
    } catch (error) {
      console.error("Error during exchange:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleFromAssetChange = (value) => {
    setFromAsset(value);
    handleExchange(amount, value, toAsset);
  };

  const handleToAssetChange = (value) => {
    setToAsset(value);
    handleExchange(amount, fromAsset, value);
  };

  const handleAmountChange = (value) => {
    setAmount(value);
    handleExchange(value, fromAsset, toAsset);
  };

  const submitSwap = async () => {
    if (!amount || !fromAsset || !toAsset) return;
    setSwapping(true);
    try {
      const response = await handlePost(`${BASE_URL}currency/exchange`, {
        amount,
        from_asset_id: fromAsset,
        to_asset_id: toAsset,
      });
      if (response) {

        fetchNetWorth();
        fetchAssets();
        fetchTransaction();
        fetchBitcoinData();
        fetchEthcoinData();
        setFromAsset(null);
        setToAsset(null);
        setAmount(null);
        setExchangeData(' ');
      }
    } catch (error) {
      console.error("Error during swap submission:", error);
    } finally {
      setSwapping(false);
    }



  };


  return (
    <section>
      <div className="">
        <div className="row">
          <div
            className={`${user?.active_status !== "approved" ? "col-md-4" : "col-md-6"
              }`}
          >
            <div className="bg-theme-white dark:bg-dark dark:border-[#334155] rounded-md px-2 py-4  h-fit">
              {graphData ? (
                <div className="">
                  <div className=" ml-3">
                    <p className="text-sm dark:text-white font-nunito font-medium flex gap-2 items-center">
                      {" "}
                      <img src={bit} alt="" />
                      {BTC} {price} {chart}
                      <div className="dark:text-white flex-col">
                        ( ${" "}
                        {graphData?.length > 0
                          ? graphData[graphData.length - 1]?.[1].toLocaleString(
                            undefined,
                            { maximumFractionDigits: 0 }
                          )
                          : "N/A"}{" "}
                        )
                      </div>
                    </p>
                  </div>
                  <ApexChart
                    name="BTC"
                    graphData={graphData}
                    color={"#4D51B7"}
                  />
                </div>
              ) : (
                <div className="w-full flex justify-center">
                  <Spin size="large" />
                </div>
              )}
            </div>
          </div>
          <div
            className={`${user?.active_status !== "approved" ? "col-md-4" : "col-md-6"
              }`}
          >
            <div className="bg-theme-white dark:bg-dark dark:border-[#334155] rounded-md px-2 py-4 h-fit p-0">
              {graphData2 ? (
                <div className="">
                  <div className=" ml-3">
                    <p className="text-sm dark:text-white font-nunito font-medium flex gap-2 items-center">
                      {" "}
                      <img src={eth} alt="" />
                      {ETH} {price} {chart}
                      <div className="dark:text-white flex-col">
                        ( ${" "}
                        {graphData2?.length > 0
                          ? graphData2[
                            graphData.length - 1
                          ]?.[1].toLocaleString(undefined, {
                            maximumFractionDigits: 0,
                          })
                          : "N/A"}{" "}
                        )
                      </div>
                    </p>
                  </div>

                  <ApexChart
                    name="BTC"
                    graphData={graphData2}
                    color={"#0990B1"}
                  />
                </div>
              ) : (
                <div className="w-full flex justify-center">
                  <Spin size="large" />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            {user?.active_status !== "approved" && (
              <div className="bg-theme-white dark:bg-dark dark:border-[#334155] rounded-md h-full">
                <div className="flex flex-col items-center p-4">
                  <h1 className="font-nunito text-lg text-center dark:text-white font-medium my-2">
                    {verify} {your} {identify} {For} {" "}
                    <br /> {enhanced} {" "}
                    {security}
                  </h1>
                  <p className="w-full dark:text-dark-text max-w-md text-sm mt-3 font-nunito font-semibold text-center">
                    {your} {account} {is}{" "}
                    {not} {verified}, {you}{" "}
                    {can} {only} {use} {the} {basic} {features},
                    {please} {verify} {your} {account}.
                  </p>
                  {user?.kyc_document_address ? (
                    <button
                      onClick={() =>
                        notification.info({ message: "KYC Completed" })
                      }
                      className="w-full h-10 mt-3 dark:bg-[#8b5cf6] bg-[#2daa2d] text-white font-nunito font-semibold rounded-lg"
                    >
                      KYC Completed
                    </button>
                  ) : (
                    <Link className="w-full" to="/verification">
                      <button className="w-full h-10 mt-3 dark:bg-[#8b5cf6] bg-hoverUser text-white font-nunito font-semibold rounded-lg">
                        {start} {kyc}
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="row p-0 m-0 w-100">
            <div className="col-md-6 mt-5 flex flex-col justify-between">
              <div className="bg-theme-white gap-[8px] min-[580px]:gap-12 px-4 py-3 dark:bg-dark   dark:border-[#334155] rounded-md flex items-start justify-between max-[1000px]:flex-wrap ">
                <h5 className="font-nunito font-medium text-2xl text-nowrap dark:text-white text-nowrap ">
                  {Overview}
                </h5>
              </div>
              <div className="bg-theme-white gap-[8px] min-[580px]:gap-12 px-4 py-3 dark:bg-dark dark:border-[#334155] rounded-md flex items-start justify-between max-[1000px]:flex-wrap ">
                <div className="">
                  <h5 className="text-base font-medium dark:text-dark-text font-nunito text-nowrap ">
                    {my} {net} {worth}
                  </h5>
                  {netWorth?.loading ? (
                    <Spin size="small" />
                  ) : (
                    <div className="text-3xl font-medium text-nowrap dark:text-white font-nunito leading-10">
                      {`$ ${Number(netWorth.value)?.toLocaleString()}`}
                    </div>
                  )}
                </div>
                {userNote?.loading ? (
                  <Spin size="small" />
                ) : (
                  <p className="font-nunito text-[#7EBA00] font-medium w-full max-w-[420px] leading-5 min-[580px]:mt-0">
                    {userNote.value}
                  </p>
                )}
              </div>
              <div className="bg-theme-white dark:bg-dark dark:border-[#334155] rounded-md   p-3">
                <div className="flex justify-between ">
                  <h1 className="font-nunito font-medium text-2xl text-nowrap dark:text-white mb-2">
                    {my} {Assets}
                  </h1>
                  <Link to="/user/assets">
                    <Button className="  text-white bg-dark">{view}{" "}{all}</Button>
                  </Link>
                </div>
                {assets ? (
                  Array.isArray(assets) &&
                  assets?.slice(0, 6)?.map((item) => {
                    return (
                      <div
                        key={item?.id}
                        className="flex items-center justify-between my-4 mb-4"
                      >
                        <div className="flex gap-1 items-center">
                          <div className="w-9 h-9 bg-dark text-white border-[0.5px] border-opacity-60 border-gray-400 rounded-full flex justify-center items-center">
                            <img
                              className="filter-class"
                              src={item?.symbol}
                              alt="Arrow"
                            />
                          </div>
                          <div className="ms-2">
                            <p className="leading-3 mb-2 dark:text-white font-nunito font-semibold">
                              {item?.name}
                            </p>
                            <p className="font-nunito font-medium text-gray-400 text-xs">{`${item?.amount} ${item?.short_name}`}</p>
                          </div>
                        </div>
                        <Button
                          onClick={() => handleNavigate(item)}
                          className="text-white bg-dark"
                        >
                          <AiOutlineArrowRight />
                        </Button>
                      </div>
                    );
                  })
                ) : (
                  <div className="w-full flex justify-center my-4">
                    <Spin size="default" />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="bg-theme-white dark:bg-dark dark:border-[#334155] rounded-md px-3 py-2">
                <div className="w-full h-16 justify-between items-center flex -ml-4">
                  <div className=" gap-[8px] min-[580px]:gap-12 px-4 py-3 dark:bg-dark   dark:border-[#334155] rounded-md flex items-start justify-between max-[1000px]:flex-wrap ">
                    <h5 className="font-nunito font-medium text-2xl text-nowrap dark:text-white text-nowrap ">
                      {The} {Trade} {Market}
                    </h5>
                  </div>

                  <h1 className="font-nunito dark:text-white text-xl text-center text-nowrap">
                    {exchange} {crypto}
                  </h1>
                </div>
                <div className="">
                  <p className="font-nunito dark:text-dark-text mb-2">{asset}</p>
                  <Select
                    size="large"
                    className="w-full mb-2"
                    placeholder="Select From Asset"
                    onChange={handleFromAssetChange}
                    options={data?.map((asset) => ({
                      value: asset.id,
                      label: asset.name + " " + asset.amount,
                    }))}
                    loading={loading}
                  />

                  <InputNumber
                    onChange={handleAmountChange}
                    placeholder="Enter Amount"
                    className="w-full my-2"
                    size="large"
                  />
                </div>
                <div className="d-flex justify-content-center my-1">
                  <img src={ph_swap} height="40px" width="40px" alt="" />
                </div>
                <div className="main">
                  <p className="font-nunito dark:text-dark-text">{asset}</p>
                  <Select
                    size="large"
                    className="w-full mb-2"
                    placeholder="Select To Asset"
                    onChange={handleToAssetChange}
                    options={data
                      .filter((asset) => asset.id !== fromAsset) // selected "from asset" ko exclude karenge
                      .map((asset) => ({
                        value: asset.id,
                        label: asset.name + " " + asset.amount,
                      }))}
                    loading={loading}
                  />
                </div>
                {loader ? (
                  <>
                    <div className="w-full flex justify-center">
                      <Spin size="large" />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="mt-4">
                  {[`${no} ${of} ${coins}`, `${network} ${fe}`, `${total}`].map((label, idx) => (
                    <div
                      key={idx}
                      className="flex justify-between my-2 items-center"
                    >
                      <h1 className="font-nunito font-medium dark:text-gray-400">
                        {label}
                      </h1>
                      <h1 className="font-nunito font-medium dark:text-gray-400">
                        {label === "No Of Coin"
                          ? exchangeData?.coins || "-"
                          : label === "Network Fee"
                            ? exchangeData?.networkfees || "-"
                            : label === "Total"
                              ? exchangeData?.TotalAmount || "-"
                              : "-"}
                      </h1>
                    </div>
                  ))}

                  <div className="flex justify-center">
                    <Button
                      size="large"
                      className="w-full h-10 font-nunito font-semibold text-lg rounded-lg my-2 bg-dark text-white"
                      onClick={submitSwap}
                      loading={swapping}
                    >
                      {Swap}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="col-md-12">
            <br />
            <div className="bg-theme-white dark:bg-dark dark:border-[#334155] rounded-md px-2 py-4">
              <div className="w-full mb-3">
                <h1 className="font-nunito mb-3 dark:text-white font-medium text-xl">
                  {Latest} {Transactions}
                </h1>
                <Link to="/user/transaction" className="">
                  <Button className="bg-dark text-white">{view}{" "}{all}</Button>
                </Link>
              </div>
              {transactions ? (
                Array.isArray(transactions) &&
                transactions?.slice(0, 3)?.map((item) => {
                  return (
                    <div className="w-full h-full" key={item?.id}>
                      <div className="flex items-center h-full my-2">
                        <div
                          className="arrow me-3 p-2 bg-dark "
                          style={{
                            boxShadow: "0px 12px 24px 0px #8F95B226",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            className="filter-class"
                            style={{ width: "34px" }}
                            src={item?.asset?.symbol}
                            alt={image}
                          />
                        </div>
                        <div className="text-content">
                          <h2 className="font-nunito">
                            <span
                              className="dark:text-white"
                              style={{ fontWeight: "900" }}
                            >
                              {item?.asset?.name}
                            </span>{" "}
                            <span className="dark:text-dark-text">
                              ({item?.status})
                            </span>
                          </h2>
                          <p className="font-nunito dark:text-dark-text">
                            {item?.amount}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="w-full flex justify-center my-4">
                  <Spin />
                </div>
              )}
            </div>
          </div>
          <br />
        </div>
      </div>
    </section>
  );
};

export default UserDashboard;
