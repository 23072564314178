import React, { useState } from 'react';
import { Button, Col, Dropdown, Radio, Row, Spin, Table, notification } from 'antd';
import icon from '../../assests/icons/tableIcon.png';
import eyeIcon from '../../assests/icons/tableeye.png';
import { Link } from 'react-router-dom';
import handlePost from '../../utils/Post';
import { BASE_URL } from '../../utils/config';
import { useTranslation } from 'react-i18next';




const data = [
    {
        key: '1',
        profile: icon,
        name: 'John Brown',
        ticket: 'Cant purchase BTC',
        details: 'New York No. 1 Lake Park',
        status: 'In Progress',
    },
    {
        key: '2',
        profile: icon,
        name: 'Jim Green',
        ticket: 'Cant purchase BTC',
        details: 'London No. 1 Lake Park',
        status: 'In Progress',
    },
    {
        key: '3',
        profile: icon,
        name: 'Joe Black',
        ticket: 'Cant purchase BTC',
        details: 'Sydney No. 1 Lake Park',
        status: 'In Progress',
    },
];

const AntDTable = ({ data, loader, link, tabManage, fetchData }) => {
    const [updateStatus, setUpdateStatus] = useState({ id: '', value: '' })
    const [open, setOpen] = useState(false)
    const { t } = useTranslation();
    const { Profile, Name, TicketTitle, Details, Status, Pending, View } = t("SupportTicket", { returnObjects: true });


    const handleUpdateStatus = async (id) => {
        if (id === updateStatus?.id) {
            await handlePost(`${BASE_URL}ticket/update/status/${id}`, { 'status': updateStatus?.value })
            await fetchData(tabManage.first ? 'ticket/admin/pending/list' : tabManage?.second ? 'ticket/admin/open/list' : 'ticket/admin/closed/list', false);
            setUpdateStatus('')
            setOpen(false)
            return;
        }
        notification.error({
            message: 'Please Select Status'
        })

    }

    const columns = [
        {
            title: Profile,
            dataIndex: 'profile',
            key: 'profile',
            render: (icon) => <img className='w-12 h-12 rounded-full' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9lzsiRta3FMohvPah30I3q-SZ6POEoMZY74pzBP8n2w&s' alt="" />,
        },
        {
            title: Name,
            dataIndex: 'title',
            key: 'title',
            render: (text) => <a className='font-nunito font-medium'>{text}</a>,
        },
        {
            title: TicketTitle,
            dataIndex: 'title',
            key: 'title',
            render: (title) => <p className='font-nunito font-medium'>{title}</p>
        },
        {
            title: Details,
            dataIndex: 'detail',
            key: 'detail',
            render: (detail) => <p className='font-nunito font-medium w-52'>{detail}</p>
        },
        {
            title: Status,
            key: 'status',
            dataIndex: 'status',
            render: (status, item) => (
                status === 'pending' ? <Dropdown open={open === item?.id} onOpenChange={setOpen} placement='bottomRight' trigger={['click']} dropdownRender={() => (
                    <div className='w-48 h-32 bg-theme-white dark:bg-[#141b2b] shadow-lg rounded-lg  py-2 px-3'>
                        <Radio.Group value={item?.id === updateStatus?.id && updateStatus?.value} onChange={e => setUpdateStatus({ id: item?.id, value: e.target.value })} className='flex flex-col'>
                            <Radio defaultChecked checked={item?.id === updateStatus?.id} className='font-nunito font-medium my-1' value='open'>OPEN</Radio>
                            <Radio className='font-nunito font-medium my-1' value='closed'>CLOSED</Radio>
                        </Radio.Group>
                        <Row gutter={6} className='mt-3'>
                            <Col>
                                <Button>Cancel</Button>
                            </Col>
                            <Col>
                                <Button onClick={() => handleUpdateStatus(item?.id)}>Update</Button>
                            </Col>
                        </Row>
                    </div>
                )}>
                    <Button onClick={() => open ? setOpen('') : setOpen(item?.id)} className='font-nunito'>{status}</Button>
                </Dropdown> :
                    <Button className='font-nunito'>{status}</Button>

            ),
        },
        {
            title: '',
            key: 'id',
            render: (id) => (
                <Link to={`${link}/ticket/${id?.id}`} className='flex items-center h-12 cursor-pointer'>
                    <img className='w-12 h-12 mt-2' src={eyeIcon} alt="eyeIcon" />
                    <p className='font-semibold font-nunito text-sm'>{View}</p>
                </Link>
            ),
        },
    ];

    return (

        <Table rowKey={item => item?.id} className='w-full min-w-[880px] font-nunito ' columns={columns} dataSource={data} />
    )
};

export default AntDTable;