import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function NavbarUi() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-white fixed top-0 w-full z-10">
            <div className="container-fluid mx-auto px-3 py-4 flex justify-between items-center">
           
                <a className="flex items-center gap-2 text-2xl font-semibold">
                    <span className="text-gray-900">The</span>
                    <span className="text-indigo-600">Trade</span>
                    <span className="text-gray-900">Market</span>
                </a>

          
                <button
                    className="lg:hidden text-gray-900 focus:outline-none"
                    type="button"
                    onClick={toggleNavbar}
                    aria-label="Toggle navigation"
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16m-7 6h7"
                        ></path>
                    </svg>
                </button>

      
                <div
                    className={`${isOpen ? 'max-h-screen' : 'max-h-0'
                        } lg:max-h-full lg:flex lg:items-center lg:space-x-6 overflow-hidden transition-all duration-500 ease-in-out w-full lg:w-auto lg:static absolute top-full left-0 bg-white lg:bg-transparent shadow-md lg:shadow-none lg:flex-row flex-col lg:py-0 py-6 z-20`}
                    id="navbarNav"
                >
                    <ul className={`flex flex-col lg:flex-row lg:items-center lg:space-x-6 w-full lg:w-auto ${isOpen ? 'mx-4' : ''}`}>
                        <li className="lg:my-0 my-2">
                            <a
                                href="#"
                                className="block py-2 lg:py-0 text-gray-900 text-base font-medium hover:text-indigo-600 transition duration-300"
                            >
                                Home
                            </a>
                        </li>
                        <li className="relative group lg:my-0 my-2">
                            <a
                                href="#"
                                className="py-2 lg:py-0 text-gray-900 text-base font-medium hover:text-indigo-600 flex items-center transition duration-300"
                            >
                                Features
                                <svg
                                    className="w-4 h-4 ml-1 group-hover:rotate-180 transition-transform duration-300"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    ></path>
                                </svg>
                            </a>

                          
                            <ul className="absolute left-0 mt-2 w-40 hidden group-hover:block bg-white shadow-lg rounded-md py-2 z-30 transition duration-300 ease-in-out">
                                <li>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                                    >
                                        Feature 1
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                                    >
                                        Feature 2
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="lg:my-0 my-2">
                            <a
                                href="#"
                                className="block py-2 lg:py-0 text-gray-900 text-base font-medium hover:text-indigo-600 transition duration-300"
                            >
                                Contact Us
                            </a>
                        </li>
                        <li className="lg:my-0 my-2">
                            <a
                                href="#"
                                className="block py-2 lg:py-0 text-gray-900 text-base font-medium hover:text-indigo-600 transition duration-300"
                            >
                                About Security
                            </a>
                        </li>
                    </ul>

                    <Link to='/signup'
                        className="block lg:ml-6 px-4 py-2 border border-indigo-600 text-[#6366f1] rounded-full hover:bg-indigo-600 hover:text-white transition lg:mt-0 duration-300"
                    >
                        Sign Up
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default NavbarUi;
