import React, { useEffect, useState } from "react";
import ph_swap from "../../../assests/images/ph_swap.png";
import { Button, InputNumber, Select, Spin } from "antd";
import { BsFillGearFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { changeHeader } from '../../../redux/reducer/headerSlice';
import handleGet from "../../../utils/Get";
import handlePost from "../../../utils/Post";
import { BASE_URL } from "../../../utils/config";
import { useTranslation } from "react-i18next";

const SwapCrypto = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));

  const [data, setData] = useState([]);
  const [fromAsset, setFromAsset] = useState('');
  const [toAsset, setToAsset] = useState('');
  const [amount, setAmount] = useState(null);
  const [exchangeData, setExchangeData] = useState('');
  const [loading, setLoading] = useState(false);
  const [swapping, setSwapping] = useState(false);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    dispatch(changeHeader(`${user?.name || "User"}, Swap Crypto`));
  }, [user, dispatch]);

  useEffect(() => {
    const fetchAssets = async () => {
      setLoading(true);
      try {
        const response = await handleGet(`${BASE_URL}active/user/assets`);
        setData(response?.assets || []);
      } catch (error) {
        console.error("Error fetching assets:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAssets();
  }, []);

  const handleExchange = async (newAmount, newFromAsset, newToAsset) => {
    if (!newAmount || !newFromAsset || !newToAsset) return;

    try {
      setloader(true);
      const response = await handlePost(`${BASE_URL}currencyexchange/onchange`, {
        amount: newAmount,
        from_asset_id: newFromAsset,
        to_asset_id: newToAsset,
      });
      if (response) setExchangeData(response?.data);
    } catch (error) {
      console.error("Error during exchange:", error);
    } finally {
      setloader(false); 
    }
  };

  const handleFromAssetChange = (value) => {
    setFromAsset(value);
    handleExchange(amount, value, toAsset);
  };

  const handleToAssetChange = (value) => {
    setToAsset(value);
    handleExchange(amount, fromAsset, value);
  };

  const handleAmountChange = (value) => {
    setAmount(value);
    handleExchange(value, fromAsset, toAsset);
  };

  const submitSwap = async () => {
    if (!amount || !fromAsset || !toAsset) return;

    setSwapping(true); 
    try {
      const response = await handlePost(`${BASE_URL}currency/exchange`, {
        amount,
        from_asset_id: fromAsset,
        to_asset_id: toAsset,
      });
      if (response) {
      
        setFromAsset('');
        setToAsset('');
        setAmount(null);
        setExchangeData('');
      }
    } catch (error) {
      console.error("Error during swap submission:", error);
    } finally {
      setSwapping(false); 
    }
  };
  const { t } = useTranslation();

  const {  The,Trade,Market,exchange,crypto,select,Swap,from,asset,enter,network,fe,no,of,coins,total ,account, } = t("dashboard", { returnObjects: true });

  return (
    <div className="w-full flex justify-center">
      <div className="w-full bg-theme-white dark:bg-dark dark:border dark:border-[#334155] px-8 pb-4 pt-2 rounded-lg my-4">
        <div className="flex items-center -ml-3">
          <h5 className="font-nunito font-bold text-sm col-md-2 col-2 ms-2 text-nowrap dark:text-white">{The} { Trade} {Market}</h5>
          <h1 className="font-nunito text-2xl col-md-8 col-8 text-center text-nowrap dark:text-gray-400">{exchange} {crypto}</h1>
        
        </div>

        <div className="mt-4">
          <p className="font-nunito dark:text-gray-400">{asset}</p>
          <Select
            size="large"
            className="w-full mb-2"
            placeholder="Select From Asset"
            onChange={handleFromAssetChange}
            options={data?.map(asset => ({ value: asset.id, label: asset.name + ' ' + (asset.amount) }))}
            loading={loading}
          />

          <InputNumber
            onChange={handleAmountChange}
            placeholder="Enter Amount"
            className="w-full my-2"
            size="large"
          />
        </div>

        <div className="w-full flex justify-center my-1">
          <img src={ph_swap} height="40px" width="40px" alt="Swap Icon" />
        </div>

        {loader ? <>
          <div className="w-full flex justify-center mt-4">
            <Spin size="large" />
          </div>

        </> : <></>
        }

        <div className="mt-4">
          <p className="font-nunito dark:text-gray-400">{asset}</p>
          <Select
            size="large"
            className="w-full mb-2"
            placeholder="Select To Asset"
            onChange={handleToAssetChange}
            options={data
              .filter(asset => asset.id !== fromAsset) // selected "from asset" ko exclude karenge
              .map(asset => ({ value: asset.id, label: asset.name + ' ' + (asset.amount) }))}
            loading={loading}
          />
          
        </div>

        <div className="mt-4">
          
          {[`${no} ${of} ${coins}`, `${network} ${fe}`, `${total}`].map((label, idx) => (
            <div key={idx} className="flex justify-between my-2 items-center">
              <h1 className="font-nunito font-medium dark:text-gray-400">{label}</h1>
              <h1 className="font-nunito font-medium dark:text-gray-400">
                {label === "No Of Coins"
                  ? exchangeData?.coins || "-"
                    : label === "Network Fee"
                      ? exchangeData?.networkfees || "-"
                      : label === "Total"
                        ? exchangeData?.TotalAmount || "-"
                        : "-"
                }
              </h1>
            </div>
          ))}

          <div className="flex justify-center">
            <Button
              size="large"
              className="w-full h-10 font-nunito font-semibold text-lg rounded-lg my-2 bg-dark text-white"
              onClick={submitSwap}
              loading={swapping} 
            >
              {Swap}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapCrypto;
