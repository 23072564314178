import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { BiArrowBack, BiChevronDown, BiChevronUp } from "react-icons/bi";

import dashboard from "../../assests/images/colorIcon.png";
import chart from "../../assests/images/Chart.png";
import pending from "../../assests/images/Combined-Shape.png";
import support from "../../assests/images/Wallet.png";

import list from "../../assests/icons/list.png";
import transaction from "../../assests/icons/usertransaction.png";
import activeDashboard from "../../assests/icons/activedashboard.png";
import activeChart from "../../assests/icons/activechart.png";
import activeTransaction from "../../assests/icons/activetransaction.png";
import activeSwap from "../../assests/icons/activewallet.png";
import stake from '../../assests/icons/stakeimg.png';

import handleRefreshToken from "../../utils/RefreshToken";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const SideBar = ({ SidebarClick }) => {
  const avatar1 = `${window.location.origin}/avatar1.png`;
  const location = useLocation();
  const path = location?.pathname?.split("/")?.[2];
  const user = JSON.parse(localStorage.getItem("user"));
  const data = useSelector((state) => state.user.value);
  const [openDropdown, setOpenDropdown] = useState(null);
  const { t } = useTranslation();
  const { needhelp, contact, our, customer, feedback, Support } = t("sidebar", { returnObjects: true });
  const transData = t("dashboard", { returnObjects: true });
  const { Dashboard, MyAssets, Transactions, Exchange, Stake, ComingSoon, Crypto } = t("UserSidebar", { returnObjects: true });

  const navItem = [
    { activeIcon: activeDashboard, icon: dashboard, title: Dashboard, url: "dashboard" },
    { activeIcon: activeChart, icon: chart, title: MyAssets, url: "assets" },
    { activeIcon: activeTransaction, icon: transaction, title: Transactions, url: "transaction" },
    {
      activeIcon: activeSwap,
      icon: support,
      title: Exchange,
      url: "swapcrypto",
      isDropdown: true,
      dropdownItems: [{ title: Crypto, url: "swapcrypto" }]
    },
    {
      activeIcon: activeSwap,
      icon: stake,
      title: Stake,
      url: "",
      isDropdown: true,
      dropdownItems: [{ title: ComingSoon, url: "" }]
    }
  ];

  useEffect(() => {
    const time = Number(localStorage.getItem("time"));
    const interval = setInterval(() => {
      if (time + 40 * 60 * 1000 < Date.now()) {
        handleRefreshToken();
        localStorage.setItem("time", Date.now());
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-screen w-full bg-theme-white dark:bg-dark overflow-hidden relative ">
      <div className="h-full flex flex-col px-4 py-6 overflow-y-auto">
        <div className="flex items-center justify-between h-20 dark:text-white">
          <h5 className="font-nunito font-bold text-2xl dark:text-white">
            {transData.The} {transData.Trade} {transData.Market}
          </h5>
          <BiArrowBack
            className="xl:hidden dark:text-white cursor-pointer"
            onClick={() => SidebarClick()}
            size={24}
          />
        </div>

        <Link to="profile" className="relative w-full flex h-16 gap-3 items-center p-3 mb-2 rounded-lg dark:bg-dark-active">
          <img className="w-12 h-12 rounded-full" src={data?.avatar_url || user?.avatar_url || avatar1} alt="avatar" />
          <div>
            <p className="text-sm font-bold dark:text-white">{user?.name || ""}</p>
            <p className="text-sm text-[#8F95B2]">{new Date().toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}</p>
          </div>
          <img className="absolute right-0 w-7 h-7" src={list} alt="list" />
        </Link>

        <div className="mt-4">
          {navItem.map(({ title, icon, url, activeIcon, isDropdown, dropdownItems }, index) => (
            <React.Fragment key={index}>
              <NavLink
                to={isDropdown ? undefined : url}
                onClick={() => setOpenDropdown(openDropdown === index ? null : index)}
                className={`flex items-center p-2 my-2 gap-3 rounded-lg ${url === path ? "bg-hoverUser dark:bg-dark-active" : "dark:hover:bg-dark-hover hover:bg-hover"} transition-all`}
              >
                <img src={path === url ? activeIcon : icon} alt="icon" className="w-8 h-8" />
                <span className={`text-base font-semibold ${url === path ? "text-white" : "text-[#8F95B2]"}`}>{title}</span>
                {isDropdown && <span className="ml-auto dark:text-white">{openDropdown === index ? <BiChevronUp /> : <BiChevronDown />}</span>}
              </NavLink>
              {isDropdown && openDropdown === index && (
                <div className="ml-8">
                  {dropdownItems.map((item, idx) => (
                    <NavLink key={idx} to={item.url} className="flex items-center p-2 mb-2 rounded-md hover:bg-hover dark:hover:bg-dark-hover">
                      <span className="text-base text-[#8F95B2]">{item.title}</span>
                    </NavLink>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="mt-auto mb-4">
          <h1 className="text-sm font-semibold dark:text-white">{needhelp}?</h1>
          <p className="text-sm text-[#8F95B2] font-semibold">
            {contact} {our} {customer} {Support}
          </p>
          <Link to="/user/support" className="flex items-center p-2 mt-2 gap-3 bg-dark hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all">
            <img src={pending} alt="" className="brightness-class" />
            <p className="text-base text-white">{feedback} & {transData.support}</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
