import React, { useEffect, useState } from 'react'
import AntDTable from '../../../components/table'
import { changeHeader } from '../../../redux/reducer/headerSlice';
import { useDispatch } from 'react-redux';
import handleGet from '../../../utils/Get';
import { BASE_URL } from '../../../utils/config'
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const Support = () => {
    const [tabManage, setTabManage] = useState({ first: true, second: false, third: false })
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { PendingTickets, Open, Close } = t("SupportTicket", { returnObjects: true });



    const fetchData = async (ticket, loading) => {
        setLoader(loading)
        const data = await handleGet(`${BASE_URL}${ticket}`)
        if (data) {
            setData(data?.ticket)
            setLoader(false)
        }
    }
    useEffect(() => {
        fetchData('ticket/admin/pending/list', true)
    }, [])



    useEffect(() => {
        dispatch(changeHeader('Admin Dashboard'));
    }, [])

    return (
        <div className='w-full h-full min-h-[420px] overflow-auto bg-theme-white dark:bg-dark dark:border dark:border-[#334155] p-2 rounded-xl'>
            <div className='m-2 flex gap-2'>
                <Button size='large' onClick={() => { setTabManage({ first: true, second: false, third: false }); fetchData('ticket/admin/pending/list', true) }} className={`${tabManage.first ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-44 p-2 rounded-lg font-nunito font-medium`}>{PendingTickets}</Button>
                <Button size='large' onClick={() => { setTabManage({ first: false, second: true, third: false }); fetchData('ticket/admin/open/list', true) }} className={`${tabManage.second ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-32 p-2 rounded-lg font-nunito font-medium`}>{Open}</Button>
                <Button size='large' onClick={() => { setTabManage({ first: false, second: false, third: true }); fetchData('ticket/admin/closed/list', true) }} className={`${tabManage.third ? 'bg-[#6C5DD3] text-white' : 'text-[#6B6B6B] bg-[#F3F3F3] dark:bg-dark-body dark:text-dark-text'} w-32 p-2 rounded-lg font-nunito font-medium`}>{Close}</Button>
            </div>
            {loader ?
                <div className='min-h-[420px] h-full w-full flex items-center justify-center'>
                    <Spin size='large' />
                </div> :
                <div className='w-full min-w-[880px] flex min-h-[420px] justify-center'>
                    <AntDTable fetchData={fetchData} tabManage={tabManage} link='/admin' loader={loader} data={data} />
                </div>
            }
        </div>
    )
}

export default Support