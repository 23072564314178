import React from 'react';
import gif from '../assets/landingpage.gif';
import { Link } from 'react-router-dom';

function HeroSection() {
    return (
        <div className="container-fluid mx-auto py-12 px-4 md:px-8 mt-24">
            <div className="flex flex-col-reverse md:flex-row items-center justify-between flex-wrap">
                <div className="w-full md:w-1/2 md:text-left">
                    <h1 className="text-4xl md:text-5xl font-bold text-gray-800 leading-snug mb-2 line-height">
                        JOIN US TO <span className='text-[#6366f1]'>EXPERIENCE</span> THE FUTURE OF PAYMENTS
                    </h1>
                    <p className="text-base md:text-lg text-gray-400 mb-6">
                        Send, spend, buy, and sell cash and crypto with Terminal Wallet.
                        It's fast, secure, and accessible anywhere!
                    </p>
                    <button className="bg-[#6366f1] text-white px-4 py-2 md:px-6 md:py-3 rounded-full text-base md:text-lg hover:bg-[#7375d1] transition">
                        
                        <Link to='/login'>
                            Get Started
                        </Link>
                    </button>
                </div>
                <div className="w-full md:w-1/2 flex justify-center md:justify-end mb-8 md:mb-0">
                    <img src={gif} alt="Landing Page Animation" className="w-4/5 rounded-lg shadow-lg" />
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
