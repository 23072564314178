import React, { useEffect, useState } from 'react'

import { Dropdown, Spin } from 'antd'
import { BiLogOut } from 'react-icons/bi'
import axios from 'axios'
import { BASE_URL } from '../../utils/config'
import { useNavigate } from 'react-router'
import { BsSunFill, BsFillMoonStarsFill } from 'react-icons/bs'
import { HiLanguage } from 'react-icons/hi2'
import { BiBell } from 'react-icons/bi'
import {  BsBell } from 'react-icons/bs'
import { RiMenu2Fill } from 'react-icons/ri'
import { LiaLanguageSolid } from 'react-icons/lia'
import { useDispatch, useSelector } from 'react-redux'
import { chnageTheme } from '../../redux/reducer/themeSlice'
import { FaEyeSlash } from "react-icons/fa";
import handleGet from '../../utils/Get'
import handlePost from '../../utils/Post'
import { FaRegEye } from "react-icons/fa";
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'


const Header = ({ setShowSiderbar }) => {
    const [dark, setDark] = useState('dark');
    const { t } = useTranslation();
    const transData = t('Select', { returnObjects: true });
    const [loading, setLoading] = useState(false);

    const changeLanguageWithLoader = (language) => {
        setLoading(true);


        setTimeout(() => {
            setLoading(false);
            i18n.changeLanguage(language);
        }, 1000);
    };



    const title = useSelector(state => state.header.title)
    const navigate = useNavigate();
    const name = title?.split(',')?.[0]
    const message = title?.split(',')?.[1]
    const dispatch = useDispatch();
    const theme = useSelector(state => state?.theme?.value);
    const data = useSelector(state => state.user.value)
    const avatar1 = `${window.location.origin}/avatar1.png`
    const user = JSON.parse(localStorage.getItem('user'));
    const [loader, setloader] = useState(false);



    const handleLogout = async () => {
        setloader(true)
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BASE_URL}logout`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'type': 'Bearer'
                }
            })
            if (response) {
                setloader(false)
                console.log('Logout successful');
                localStorage.removeItem('token');
                localStorage.removeItem('timestamp');
                localStorage.removeItem('time');
                navigate('/login');
                return;
            }
        }
        catch (err) {
            setloader(false)
            console.log(err)
        }
    }


    const handleDarkMode = () => {
        setDark(prev => prev === 'dark' ? 'light' : 'dark');
    }

    useEffect(() => {
        if (dark === 'dark') {
            dispatch(chnageTheme(dark));
            localStorage.setItem('theme', 'dark');
            document.documentElement.classList.add('dark')
        }
        else {
            dispatch(chnageTheme(dark));
            document.documentElement.classList.remove('dark')
            localStorage.setItem('theme', 'dark');
        }
    }, [dark])


    const [UserNotification, setnotification] = useState('');

    const fetchNotification = async () => {
        const data = await handleGet(`${BASE_URL}active/user/notification`);
        setnotification(data?.data);
    }


    useEffect(() => {
        fetchNotification();
    }, []);








    const HandleRead = async (id, currentStatus) => {
        try {
            const newStatus = currentStatus === false ? '1' : '0';
            const response = await handlePost(`${BASE_URL}get/notifications/${id}`, {
                status: newStatus
            });
            if (response) {
                fetchNotification();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='w-full flex justify-between items-end'>
            <div>
                <p className='font-nunito font-bold dark:text-white leading-4'>Hi {name},</p>
                <div className='flex items-center'>
                    <div className='flex items-center gap-1 dark:text-white'>
                        <RiMenu2Fill className='visible xl:hidden' onClick={() => setShowSiderbar(true)} size={24} />
                        <h1 className='text-xl md:text-2xl lg:text-3xl text-nowrap font-nunito dark:text-dark-text text-[#081735] font-bold'>
                            {message}
                        </h1>
                    </div>
                </div>
            </div>
            <ul className='h-full flex gap-6 items-center'>
                <li onClick={handleDarkMode} className='w-10 h-10 rounded-full cursor-pointer dark:border dark:border-[#334155] dark:bg-dark bg-theme-white hidden min-[525px]:flex items-center justify-center shadow-xl'>
                    <p className='dark:text-white text-[#7EBA00]'>{theme !== 'dark' ? <BsSunFill size={24} /> : < BsFillMoonStarsFill />}
                    </p>
                </li>



                <Dropdown
                    placement='bottomleft'
                    trigger={['click']}
                    dropdownRender={() => (
                        <>
                            <ul
                                id="dropdownMenu"
                                className="absolute block shadow-lg bg-theme-white dark:bg-dark dark:border-[#334155] py-2 px-2 z-[1000] min-w-full w-max rounded mt-8 max-h-96 overflow-auto"
                            >
                                <li
                                    value="en"
                                    onClick={() => changeLanguageWithLoader('en')}
                                    className="py-2.5 px-4 rounded dark:text-white text-sm cursor-pointer hover:bg-hover dark:hover:bg-dark-hover "
                                >
                                    <div className="flex items-center">
                                        <img
                                            src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
                                            className="w-6 mr-3"
                                            alt="English flag"
                                        />
                                        {transData.first}
                                    </div>
                                </li>

                                <li
                                    value="fr"
                                    onClick={() => changeLanguageWithLoader('fr')}
                                    className="py-2.5 px-4 rounded dark:text-white text-sm cursor-pointer hover:bg-hover dark:hover:bg-dark-hover "
                                >
                                    <div className="flex items-center">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/640px-Flag_of_Germany.svg.png"
                                            className="w-6 mr-3"
                                            alt="French flag"
                                        />
                                        {transData.second}
                                    </div>
                                </li>

                                <li
                                    value="de"
                                    onClick={() => changeLanguageWithLoader('de')}
                                    className="py-2.5 px-4 rounded dark:text-white text-sm cursor-pointer hover:bg-hover dark:hover:bg-dark-hover "
                                >
                                    <div className="flex items-center">
                                        <img
                                            src="https://cdn.britannica.com/82/682-004-F0B47FCB/Flag-France.jpg"
                                            className="w-6 mr-3"
                                            alt="German flag"
                                        />
                                        {transData.third}
                                    </div>
                                </li>
                            </ul>
                        </>
                    )}
                >
                    <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                        {loading ? (
                            <>
                                <div className=''>
                                    <div className="loader-language">

                                    </div>
                                </div>
                            </>

                        ) : (
                            <p className='dark:text-white text-[#7EBA00]'>
                                <LiaLanguageSolid size={24} />
                            </p>
                        )}
                    </li>
                </Dropdown>

                <Dropdown placement='bottomLeft' trigger={['click']}
                    dropdownRender={() => (
                        <>

                            <div

                                className="absolute block right-0 shadow-lg bg-theme-white dark:bg-dark dark:border-[#334155]  py-2 z-[1000] min-w-full rounded-lg w-[410px] max-h-[300px] overflow-auto mt-2"
                            >
                                <h3 className="text-md mb-3 p-3 dark:text-white font-semibold">
                                    Notification  
                                    <p className='mt-2'>You have {UserNotification?.length} unread notifications</p>

                                </h3>
                                <ul className="divide-y">
                                    {UserNotification?.map((item, index) => (
                                        <li key={index} className="p-3 flex justify-between">
                                            {item?.status === false ?
                                                <>
                                                    <img
                                                        src={item?.user?.avatar_url || avatar1}
                                                        className="w-14 h-14 rounded-full shrink-0"
                                                    />
                                                    <div className="">
                                                        <p className="text-xs dark:text-white mt-2">
                                                            <li className='capitalize w-60 text-start'>{item?.message}</li>
                                                        </p>
                                                    </div>



                                                </>
                                                :
                                                <div className='opacity-25 flex  justify-between'>
                                                    <img
                                                        src={item?.user?.avatar_url || avatar1}
                                                        className="w-14 h-14 rounded-full shrink-0"
                                                    />
                                                    <div className="">
                                                        <p className="text-xs dark:text-white mt-2">
                                                            <li className='capitalize w-60 text-start ml-7'>{item?.message}</li>
                                                        </p>
                                                    </div>



                                                </div>
                                            }
                                            <div
                                                className="dark:text-white cursor-pointer dark:border-[#334155] text-2xl"
                                                onClick={() => HandleRead(item?.id, item?.status)}
                                            >
                                                {item?.status === false ? <FaEyeSlash /> : <FaRegEye />}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>


                        </>
                    )}
                >
                    <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                        <p className='dark:text-white text-[#7EBA00]'> <BsBell size={24} /></p>
                    </li>
                </Dropdown>




                <Dropdown placement='bottomLeft' trigger={['click']}
                    dropdownRender={() => (
                        <div className='dark:bg-dark dark:border dark:border-[#334155] bg-theme-white cursor-pointer w-40 shadow-md h-fit rounded-lg p-2'>
                            <li onClick={handleLogout} className='flex gap-2 items-center h-10 bg-dark text-white rounded-md transition-all duration-300  p-2'>
                                {loader ? <>  <Spin size="medium" /></> : <BiLogOut size={24} />}

                                <p className='font-nunito text-lg font-semibold flex'>
                                    Logout</p>
                            </li>
                            <li onClick={handleDarkMode} className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                {theme !== 'dark' ? <BsSunFill size={24} /> : < BsFillMoonStarsFill />}
                                <p className='font-nunito text-lg font-semibold'>Darkmode</p>
                            </li>
                            <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                <HiLanguage size={24} />
                                <p className='font-nunito text-lg font-semibold'>Language</p>
                            </li>
                            <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                <BiBell size={24} />
                                <p className='font-nunito text-lg font-semibold'>Notification</p>
                            </li>

                        </div>
                    )}
                >
                    <li className='w-10 h-10 rounded-full bg-theme-white dark:border dark:border-[#334155] dark:bg-dark flex cursor-pointer items-center justify-center shadow-xl'>
                        <img className='w-10 h-10 rounded-full' src={data?.avatar_url ? data?.avatar_url : user?.avatar_url ? user?.avatar_url : avatar1} alt="item" />
                    </li>
                </Dropdown>
            </ul>



        </div>
    )
}

export default Header;