import React from 'react';
import tokenimg from '../assets/bitcoin.webp';

const tokens = [
    { name: 'Bitcoin', img: tokenimg },
    { name: 'Ethereum', img: tokenimg },
    { name: 'Cardano', img: tokenimg },
    { name: 'Ripple', img: tokenimg },
    { name: 'Litecoin', img: tokenimg },
    { name: 'Polkadot', img: tokenimg },
];

function Tokens() {
    return (
        <div className="container-fluid mx-auto px-4 py-10 rounded-lg mt-10">
            <h1 className="text-4xl font-bold text-gray-800 text-center mb-8">
                Available <span className='text-blue-600'>Tokens</span>
            </h1>
            <div className="flex flex-wrap justify-center gap-4">
                {tokens.map((token) => (
                    <div key={token.name} className="flex items-center bg-white rounded-full shadow-md p-2 transition-transform transform hover:scale-105">
                        <img src={token.img} alt={token.name} className="w-8 h-8 mr-2" />
                        <span className="text-lg font-semibold text-gray-800">{token.name}</span>
                    </div>
                ))}
            </div>


         


        </div>
    );
}

export default Tokens;
