import React, { useEffect, useState } from 'react'
import logo from '../assests/icons/Group.png'
import darkmode from '../assests/icons/Vector2.png'
import language from '../assests/icons/Frame.png'
import avatar from '../assests/icons/Oval.png'
import email from '../assests/icons/verificationemail.png'
import app from '../assests/icons/verificationapp.png'
import { MdKeyboardArrowDown, MdOutlineSmartphone } from 'react-icons/md'
import { Button, Dropdown, Input, InputNumber, Modal, Progress, Result, Spin, notification, theme } from 'antd'
import arrow from '../assests/icons/backarrow.png'
import OtpInput from 'react-otp-input';
import lock from '../assests/icons/lock.png'
import { IoLocationSharp } from 'react-icons/io5'
import { BiBell, BiLogOut, BiSolidCity } from 'react-icons/bi'
import verimg from '../../src/assests/images/convert2.png'
import uploadimg from '../../src/assests/icons/uploadicon.png'
import { BsBell, BsFillMoonStarsFill, BsFillSunFill, BsSunFill, BsUpload } from 'react-icons/bs';
import veraddress from '../../src/assests/images/convert1.png'
import congratsimg from '../../src/assests/images/convert.png'
import { Upload } from 'antd';
import { Link } from 'react-router-dom'
import handlePost from '../utils/Post'
import { BASE_URL } from '../utils/config'
import handleGet from '../utils/Get'
import { HiLanguage } from 'react-icons/hi2'
import { LiaLanguageSolid } from 'react-icons/lia'
import { useDispatch } from 'react-redux';
import { chnageTheme } from '../redux/reducer/themeSlice'
import { useNavigate } from 'react-router-dom'
import Logo from '../assests/images/new_logo.jpg';
import axios from 'axios'
import i18n from '../i18n'
import { FaEyeSlash, FaRegEye } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'



const Verification = () => {

    const [screen, setScreen] = useState({ first: true, second: false, third: false, fourth: false, fifth: false, sixth: false })
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleSecond, setIsModalVisibleSecond] = useState(false);
    const [file, setFile] = useState({ kyc_document_id: '', kyc_document_address: '' });
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState({ code: false, user: false })
    const [data, setData] = useState({ name: '', email: '', phone_number: '', address: '', city: '', country: '', postal_code: '' })
    const progress = 100 / 6;
    const user = JSON.parse(localStorage.getItem('user'));
    const [dark, setDark] = useState('dark');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setloader] = useState(false);
    const [loaderlan, setloaderlan] = useState(false);

    const avatar1 = `${window.location.origin}/avatar1.png`

    const { t } = useTranslation();
    const transData = t('Select', { returnObjects: true });
   const{SelectFiles,OK,Username,Uploadaproofofaddressdocumentsuchasautilitybillorbankstatement,Awesomeyourinformationwassubmittedsuccessfullyforreview,Cancel,Youreallset,GotoDashboard,Wewillreviewyourinformationandapproveyouraccountshortly,Verifyyouraddress, Dropfilestoupload,OR,UploadaphotoofyourIDcardpassportordrivinglicenseMakesureitisvalidandnotexpired,Tellusaboutyourself,Weneedthisinformationtoverifyyouridentity,PhoneNumber,Address,City,PostalCode,Country,Verifyyouridentity,UploadDocument,  EnteryourCode,Step1,Continue,TheTradeMarket,   Wesentyouacodetoyouremail , GoBack,TwofactorAuthentication,WithEmail,Enabletwofactorauthentication,Addanextralayerofsecuritytoyouraccount,Wewillsendaconfirmationcodetoyouremailaddress}= t('KYC', { returnObjects: true });



    const showModal = () => {
        setIsModalVisible(true);
    };

    const showModalSecond = () => {
        setIsModalVisibleSecond(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setScreen({ ...screen, })
    };

    const handleOkSecond = async () => {
        const response = await handlePost(`${BASE_URL}user/upload/kyc`, file);
        if (response) {
            setScreen({ ...screen, fifth: false, sixth: true })
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [UserNotification, setnotification] = useState('');

    const fetchNotification = async () => {
        const data = await handleGet(`${BASE_URL}active/user/notification`);
        setnotification(data?.data);
    }


    useEffect(() => {
        fetchNotification();
    }, []);

    const HandleRead = async (id, currentStatus) => {
        try {
            const newStatus = currentStatus === false ? '1' : '0';
            const response = await handlePost(`${BASE_URL}get/notifications/${id}`, {
                status: newStatus
            });
            if (response) {
                fetchNotification();
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleGetCode = async () => {
        setLoading({ ...loading, code: true });
        try {
            const code = await handleGet(`${BASE_URL}2fa/sendcode`);
            console.log(code)
            if (code) {
                notification.success({ message: code?.message })
                setScreen({ ...screen, first: false, second: true })
            }
            else {
                notification.error({ message: 'Request failed ' })
            }
            setLoading({ ...loading, code: false })
        }
        catch (error) {
            setLoading({ ...loading, code: false })
            console.log(error);
        }
    };

    const handleVerifyCode = async () => {
        const response = await handlePost(`${BASE_URL}2fa/verifycode`, { code: otp })
        if (response) {
            setScreen({ ...screen, second: false, third: true })
        }
    };

    const handleUpdateUser = async () => {
        setLoading({ ...loading, user: true });
        const formData = {
            email: user?.email,
            name: data?.name,
            'details[phone_number]': data?.phone_number,
            'details[address]': data?.address,
            'details[city]': data?.city,
            'details[country]': data?.country,
            'details[postal_code]': data?.postal_code
        }
        try {
            const response = await handlePost(`${BASE_URL}update/user/${user?.id}`, formData)
            if (response) {
                setScreen({ ...screen, third: false, fourth: true })
            }
            setLoading({ ...loading, user: false });
        } catch (error) {
            console.log(error)
            setLoading({ ...loading, user: false });
        }
    };
    const changeLanguageWithLoader = (language) => {
        setloaderlan(true);


        setTimeout(() => {
            setloaderlan(false);
            i18n.changeLanguage(language);
        }, 1000);
    };

    const handleLogout = async () => {
        setloader(true)
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BASE_URL}logout`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'type': 'Bearer'
                }
            })
            if (response) {
                setloader(false)
                console.log('Logout successful');
                localStorage.removeItem('token');
                localStorage.removeItem('timestamp');
                localStorage.removeItem('time');
                navigate('/login');
                return;
            }
        }
        catch (err) {
            setloader(false)
            console.log(err)
        }
    }
  

    const handleDarkMode = () => {
        setDark(prev => prev === 'dark' ? 'light' : 'dark');
    };

    const handleCompleteVerification = async () => {
        setLoading({ ...loading, user: true });
        try {
            const response = await handleGet(`${BASE_URL}get/user/detail/${user?.id}`);
            localStorage.setItem('user', JSON.stringify(response?.user));
            setLoading({ ...loading, user: false });
            navigate('/user');
        }
        catch (err) {
            console.log(err);
            setLoading({ ...loading, user: false });
        };
    };

    useEffect(() => {
        if (dark === 'dark') {
            dispatch(chnageTheme(dark));
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', dark);
        }
        else {
            dispatch(chnageTheme(dark));
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', dark);
        }
    }, [dark]);


  


    return (
        <div className='overflow-x-hidden dark:bg-dark h-screen'>

            <div className='flex h-20 px-8' >
                <div className='w-full flex items-center gap-6 py-2'>
                    <div className=" gap-[8px] min-[580px]:gap-12 px-4 py-3 dark:bg-dark   dark:border-[#334155] rounded-md flex items-start justify-between max-[1000px]:flex-wrap ">
                        <h5 className="font-nunito font-bold text-2xl text-nowrap dark:text-white text-nowrap ">
                            {TheTradeMarket}
                        </h5>
                    </div>
                    <div className='h-full w-[1px] bg-line mx-2' />
                    <div className='flex h-full items-center'>
                        <p className='text-sm text-gray-400 dark:text-white font-nunito'>{Step1}:</p>
                        <p className='text-[#000] dark:text-dark-text font-nunito mx-1 font-semibold'>{TwofactorAuthentication}</p>
                        <MdKeyboardArrowDown size={20} className='mt-[2px]' />
                    </div>
                </div>
                <ul className='h-full flex gap-6 items-center'>
                <li onClick={handleDarkMode} className='w-10 h-10 rounded-full cursor-pointer dark:border dark:border-[#334155] dark:bg-dark bg-theme-white hidden min-[525px]:flex items-center justify-center shadow-xl'>
                    <p className='dark:text-white text-[#7EBA00]'>{theme !== 'dark' ? <BsSunFill size={24} /> : < BsFillMoonStarsFill />}
                    </p>
                </li>



                <Dropdown
                    placement='bottomleft'
                    trigger={['click']}
                    dropdownRender={() => (
                        <>
                            <ul
                                id="dropdownMenu"
                                className="absolute block shadow-lg bg-theme-white dark:bg-dark dark:border-[#334155] py-2 px-2 z-[1000] min-w-full w-max rounded mt-8 max-h-96 overflow-auto"
                            >
                                <li
                                    value="en"
                                    onClick={() => changeLanguageWithLoader('en')}
                                    className="py-2.5 px-4 rounded dark:text-white text-sm cursor-pointer hover:bg-hover dark:hover:bg-dark-hover "
                                >
                                    <div className="flex items-center">
                                        <img
                                            src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
                                            className="w-6 mr-3"
                                            alt="English flag"
                                        />
                                        {transData.first}
                                    </div>
                                </li>

                                <li
                                    value="fr"
                                    onClick={() => changeLanguageWithLoader('fr')}
                                    className="py-2.5 px-4 rounded dark:text-white text-sm cursor-pointer hover:bg-hover dark:hover:bg-dark-hover "
                                >
                                    <div className="flex items-center">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/640px-Flag_of_Germany.svg.png"
                                            className="w-6 mr-3"
                                            alt="French flag"
                                        />
                                        {transData.second}
                                    </div>
                                </li>

                                <li
                                    value="de"
                                    onClick={() => changeLanguageWithLoader('de')}
                                    className="py-2.5 px-4 rounded dark:text-white text-sm cursor-pointer hover:bg-hover dark:hover:bg-dark-hover "
                                >
                                    <div className="flex items-center">
                                        <img
                                            src="https://cdn.britannica.com/82/682-004-F0B47FCB/Flag-France.jpg"
                                            className="w-6 mr-3"
                                            alt="German flag"
                                        />
                                        {transData.third}
                                    </div>
                                </li>
                            </ul>
                        </>
                    )}
                >
                    <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                            {loaderlan ? (
                            <>
                                <div className=''>
                                    <div className="loader-language">

                                    </div>
                                </div>
                            </>

                        ) : (
                            <p className='dark:text-white text-[#7EBA00]'>
                                <LiaLanguageSolid size={24} />
                            </p>
                        )}
                    </li>
                </Dropdown>

                <Dropdown placement='bottomLeft' trigger={['click']}
                    dropdownRender={() => (
                        <>

                            <div

                                className="absolute block right-0 shadow-lg bg-theme-white dark:bg-dark dark:border-[#334155]  py-2 z-[1000] min-w-full rounded-lg w-[410px] max-h-[300px] overflow-auto mt-2"
                            >
                                <h3 className="text-md mb-3 p-3 dark:text-white font-semibold">
                                    Notification  
                                    <p className='mt-2'>You have {UserNotification?.length} unread notifications</p>

                                </h3>
                                <ul className="divide-y">
                                    {UserNotification?.map((item, index) => (
                                        <li key={index} className="p-3 flex justify-between">
                                            {item?.status === false ?
                                                <>
                                                    <img
                                                        src={item?.user?.avatar_url || avatar1}
                                                        className="w-14 h-14 rounded-full shrink-0"
                                                    />
                                                    <div className="">
                                                        <p className="text-xs dark:text-white mt-2">
                                                            <li className='capitalize w-60 text-start'>{item?.message}</li>
                                                        </p>
                                                    </div>



                                                </>
                                                :
                                                <div className='opacity-25 flex  justify-between'>
                                                    <img
                                                        src={item?.user?.avatar_url || avatar1}
                                                        className="w-14 h-14 rounded-full shrink-0"
                                                    />
                                                    <div className="">
                                                        <p className="text-xs dark:text-white mt-2">
                                                            <li className='capitalize w-60 text-start ml-7'>{item?.message}</li>
                                                        </p>
                                                    </div>



                                                </div>
                                            }
                                            <div
                                                className="dark:text-white cursor-pointer dark:border-[#334155] text-2xl"
                                                onClick={() => HandleRead(item?.id, item?.status)}
                                            >
                                                {item?.status === false ? <FaEyeSlash /> : <FaRegEye />}
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                            </div>


                        </>
                    )}
                >
                    <li className='w-10 h-10 rounded-full dark:border dark:border-[#334155] bg-theme-white dark:bg-dark hidden min-[525px]:flex cursor-pointer items-center justify-center shadow-xl'>
                        <p className='dark:text-white text-[#7EBA00]'> <BsBell size={24} /></p>
                    </li>
                </Dropdown>




                <Dropdown placement='bottomLeft' trigger={['click']}
                    dropdownRender={() => (
                        <div className='dark:bg-dark dark:border dark:border-[#334155] bg-theme-white cursor-pointer w-40 shadow-md h-fit rounded-lg p-2'>
                            <li onClick={handleLogout} className='flex gap-2 items-center h-10 bg-dark text-white rounded-md transition-all duration-300  p-2'>
                                {loader ? <>  <Spin size="medium" /></> : <BiLogOut size={24} />}

                                <p className='font-nunito text-lg font-semibold flex'>
                                    Logout</p>
                            </li>
                            <li onClick={handleDarkMode} className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                {theme !== 'dark' ? <BsSunFill size={24} /> : < BsFillMoonStarsFill />}
                                <p className='font-nunito text-lg font-semibold'>Darkmode</p>
                            </li>
                            <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                <HiLanguage size={24} />
                                <p className='font-nunito text-lg font-semibold'>Language</p>
                            </li>
                            <li className='flex min-[525px]:hidden gap-2 items-center h-10 hover:bg-hover dark:hover:bg-dark-hover rounded-md transition-all duration-300 p-2'>
                                <BiBell size={24} />
                                <p className='font-nunito text-lg font-semibold'>Notification</p>
                            </li>

                        </div>
                    )}
                >
                    <li className='w-10 h-10 rounded-full bg-theme-white dark:border dark:border-[#334155] dark:bg-dark flex cursor-pointer items-center justify-center shadow-xl'>
                        <img className='w-10 h-10 rounded-full' src={data?.avatar_url ? data?.avatar_url : user?.avatar_url ? user?.avatar_url : avatar1} alt="item" />
                    </li>
                </Dropdown>
            </ul>

            </div>
            <Progress status='success' percent={screen.first ? progress : screen.second ? progress * 2 : screen.third ? progress * 3 : screen.fourth ? progress * 4 : screen.fifth ? progress * 5 : screen.sixth && progress * 6} type='line' strokeColor='#9185DE' size='small' showInfo={false} />
            {
                screen?.first &&
                <>
                    <div className='w-full flex flex-col items-center my-8 p-7 md:p-9'>
                        <div className='flex flex-col items-center'>
                            <p className='md:text-2xl dark:text-white sm:text-xl text-lg font-nunito font-bold'>{Enabletwofactorauthentication}</p>
                            <p className='font-nunito dark:text-dark-text text-sm text-gray-400 my-2'>{Addanextralayerofsecuritytoyouraccount}</p>
                            <div className='flex gap-4 my-4'>
                                <div className='max-w-sm w-full h-64 p-2 rounded-xl border-1 border-gray-400 flex flex-col items-center' >
                                    <img className='w-48 h-36 mt-3' src={app} alt="app" />
                                    <p className='font-nunito dark:text-white text-xl font-bold mt-2'>{WithEmail}</p>
                                    <p className='text-sm text-gray-400 font-nunito font-medium'>{Wewillsendaconfirmationcodetoyouremailaddress}</p>
                                </div>
                            </div>
                            <Button loading={loading?.code} onClick={handleGetCode} className='w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-4 rounded-xl bg-hoverUser'>{Continue}</Button>
                        </div>
                    </div>
                </>
            }
            {
                screen?.second &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, second: false, first: true })} className='w-8 h-8' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>{GoBack}</p>
                    </div>
                    <div className='w-full flex flex-col items-center'>
                        <img className='w-14 mb-8 h-14' src={lock} alt="" />
                        <p className='text-2xl font-nunito font-bold dark:text-white'>{EnteryourCode}</p>
                        <p className='font-nunito text-sm text-gray-400 dark:text-dark-text my-2 leading-3'>{Wesentyouacodetoyouremail}</p>
                        <OtpInput
                            value={otp}
                            containerStyle={{
                                margin: "auto",
                                marginBottom: "20px",
                                marginTop: 32,
                                justifyContent: "center",
                            }}
                            onChange={setOtp}
                            inputStyle={{
                                width: "48px",
                                padding: "12px",
                                border: "1px solid #ACACAC",
                                borderRadius: 12
                            }}
                            numInputs={6}
                            renderSeparator={
                                <span style={{ marginInline: "5px" }}>  </span>
                            }
                            renderInput={(props) => <input {...props} />}
                        />
                        <Button onClick={handleVerifyCode} className='w-fit h-fit  px-12 py-3 font-nunito text-sm text-white mt-20 rounded-xl bg-hoverUser mb-5'>{Continue}</Button>
                    </div>
                </div>
            }
            {
                screen?.third &&
                <div className='p-2'>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, third: false, second: true })} className='w-8 h-8 cursor-pointer' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>{GoBack}</p>
                    </div>
                    <div className='w-full flex flex-col items-center'>
                        <p className='text-2xl font-nunito font-bold dark:text-white'>{Tellusaboutyourself}</p>
                        <p className='font-nunito text-sm dark:text-dark-text text-gray-400 my-2 leading-3'>{Weneedthisinformationtoverifyyouridentity}</p>
                        <div className='w-full max-w-xl'>
                            <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>{Username}</p>
                            <Input value={data?.name} onChange={e => setData({ ...data, name: e.target.value })} className='mb-3' size='large' placeholder='Jane Eyre' />
                            <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>{PhoneNumber}</p>
                            <InputNumber value={data?.phone_number} onChange={e => setData({ ...data, phone_number: e })} prefix={<MdOutlineSmartphone size={20} color='gray' />} className='w-full mb-3' type='number' size='large' placeholder='i.e 03xxxxxxxxxxxxx' />
                            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-2'>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>{Address}</p>
                                    <Input value={data?.address} onChange={e => setData({ ...data, address: e.target.value })} prefix={<IoLocationSharp size={20} color='gray' />} className='mb-3' size='large' placeholder={Address} />
                                </div>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>{City}</p>
                                    <Input value={data?.city} onChange={e => setData({ ...data, city: e.target.value })} prefix={<BiSolidCity size={20} color='gray' />} className='mb-3' size='large' placeholder={City} />
                                </div>
                            </div>
                            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-2'>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>{Country}</p>
                                    <Input value={data?.country} onChange={e => setData({ ...data, country: e.target.value })} className='mb-3' size='large' placeholder={Country} />
                                </div>
                                <div className='col-span-1'>
                                    <p className='font-nunito my-0.5 font-medium dark:text-dark-text'>{PostalCode}</p>
                                    <Input value={data?.postal_code} onChange={e => setData({ ...data, postal_code: e.target.value })} className='mb-3' size='large' placeholder={PostalCode} />
                                </div>
                            </div>
                        </div>
                        <Button loading={loading?.user} onClick={handleUpdateUser} className='w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'>{Continue}</Button>
                    </div>
                </div>
            }
            {
                screen?.fourth &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, fourth: false, third: true })} className='w-8 h-8 cursor-pointer' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>{GoBack}</p>
                    </div>
                    <div className='justify-center text-center font-nunito'>
                        <h2 className='font-bold text-3xl dark:text-white text-[#000000]'>{Verifyyouridentity}</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>{UploadaphotoofyourIDcardpassportordrivinglicenseMakesureitisvalidandnotexpired}</p>
                        <img className='justify-center m-auto w-802 h-80' src={verimg} alt="" />
                        <div>
                            <button
                                className='m-auto flex w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'
                                onClick={showModal}
                            >
                                <img className='me-2 w-4 h-4' src={uploadimg} alt="" />
                                {UploadDocument}
                            </button>
                            <Modal
                                centered={true}
                                title="Upload Document"
                                open={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                footer={[
                                    <Button key="back" onClick={handleCancel}>
                                        {Cancel}
                                    </Button>,
                                    <Button onClick={handleOk}>
                                        {OK}
                                    </Button>,
                                ]}
                            >
                                <Upload.Dragger beforeUpload={e => { setFile({ ...file, kyc_document_id: e }); setScreen({ ...screen, fourth: false, fifth: true }) }} showUploadList={false}>
                                    <p className="d-flex justify-content-center align-items-center">
                                        <BsUpload size={20} />
                                    </p>

                                    <div className='font-nunito mt-2'>
                                        <p className="text-[#6B6B6B] text-lg">{Dropfilestoupload} </p>
                                        <p className='text-[#6B6B6B] text-sm'>{OR}</p>
                                        <p className="text-[#6B6B6B] text-lg">
                                            Select Files
                                        </p>
                                    </div>
                                </Upload.Dragger>
                            </Modal>
                        </div>
                    </div>

                </div>
            }
            {
                screen?.fifth &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img onClick={() => setScreen({ ...screen, fifth: false, fourth: true })} className='w-8 h-8' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>{GoBack}</p>
                    </div>
                    <div className='justify-center text-center font-nunito'>
                        <h2 className='font-bold text-3xl text-[#000000] dark:text-white'>{Verifyyouraddress}</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>{Uploadaproofofaddressdocumentsuchasautilitybillorbankstatement}</p>
                        <img className='justify-center m-auto w-802 h-80' src={veraddress} alt="" />
                        <div>
                            <button
                                className='m-auto flex w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'
                                onClick={showModalSecond}
                            >
                                <img className='me-2 w-4 h-4' src={uploadimg} alt="" />
                                {UploadDocument}
                            </button>
                            <Modal
                                centered={true}
                                title="Upload Document"
                                open={isModalVisibleSecond}
                                onOk={handleOkSecond}
                                onCancel={() => setIsModalVisibleSecond(false)}
                                footer={[
                                    <Button key="back" onClick={() => setIsModalVisibleSecond(false)}>
                                        {Cancel}
                                    </Button>,
                                    <Button onClick={handleOkSecond}>
                                        {OK}
                                    </Button>,
                                ]}
                            >
                                <Upload.Dragger beforeUpload={e => setFile({ ...file, kyc_document_address: e })} showUploadList={false}>
                                    {!file?.kyc_document_address ? <>
                                        <p className="d-flex justify-content-center align-items-center">
                                            <BsUpload size={20} />
                                        </p>

                                        <div className='font-nunito mt-2'>
                                            <p className="text-[#6B6B6B] text-lg">{Dropfilestoupload} </p>
                                            <p className='text-[#6B6B6B] text-sm'>{OR}</p>
                                            <p className="text-[#6B6B6B] text-lg">
                                                Select Files
                                            </p>
                                        </div>
                                    </> :
                                        <Result status='success' />
                                    }
                                </Upload.Dragger>
                            </Modal>
                        </div>
                    </div>

                </div>
            }
            {
                screen?.sixth &&
                <div>
                    <div className='w-full h-12 items-center flex mx-[30px] gap-2'>
                        <img className='w-8 h-8' src={arrow} alt="" />
                        <p className='font-nunito font-medium dark:text-white'>{GoBack}</p>
                    </div>
                    <div className='justify-center text-center font-nunito'>
                        <h2 className='font-bold text-3xl text-[#000000] dark:text-white'>Congrats! {Youreallset}</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>{Awesomeyourinformationwassubmittedsuccessfullyforreview}</p>
                        <img className='justify-center m-auto  h-80' src={congratsimg} alt="" />
                        <h2 className='font-bold text-2xl text-[#000000] dark:text-white'>{Youreallset}</h2>
                        <p className='text-[#6B6B6B] dark:text-dark-text'>{Wewillreviewyourinformationandapproveyouraccountshortly}</p>
                        <div>
                            <Button loading={loading?.user} onClick={handleCompleteVerification} className='mt-3 m-auto flex w-fit h-fit px-12 py-3 font-nunito text-sm text-white my-2 rounded-xl bg-hoverUser'>
                                {GotoDashboard}
                            </Button>
                        </div>
                    </div>

                </div>
            }
        </div>
    )

};
export default Verification