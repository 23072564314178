import React, { useEffect, useState } from 'react'
import user from '../../../assests/userimg.png'
import Card from '../../../components/cards/card'
import { Link } from 'react-router-dom'
import { Input, Pagination, Spin } from 'antd'
import { BiSearch } from 'react-icons/bi'
import { changeHeader } from '../../../redux/reducer/headerSlice';
import { useDispatch } from 'react-redux';
import handleGet from '../../../utils/Get'
import { BASE_URL } from '../../../utils/config'
import { useTranslation } from 'react-i18next'

const UserManagement = () => {
    const dispatch = useDispatch();
    const [searchUser, setSearchUser] = useState('');
    const [data, setData] = useState('')
    const [current, setCurrent] = useState(1);
    const itemsPerPage = 6;
    const startIndex = (current - 1) * itemsPerPage;
    const endIndex = current * itemsPerPage;
    const itemsToDisplay = data?.slice(startIndex, endIndex);
    const { t } = useTranslation();
    const { UsersManagement} = t("AgencyManagement", { returnObjects: true });


    const onChange = (page) => {
        setCurrent(page);
    };

    useEffect(() => {
        dispatch(changeHeader(UsersManagement));
    }, [UsersManagement])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await handleGet(`${BASE_URL}get/all/users?search=${searchUser}`)
                setData(data?.users);
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [searchUser])

    return (
        <div>
            <Input onChange={e => setSearchUser(e.target.value)} value={searchUser} className='max-w-xs h-10 shadow-md rounded-xl' placeholder='Search Users' prefix={<BiSearch size={24} />} />
            {itemsToDisplay ?
                <div className=''>
                    <div className='w-full grid grid-cols-1 min-[520px]:grid-cols-2 lg:grid-cols-3 gap-4 my-10'>
                        {Array.isArray(itemsToDisplay) && itemsToDisplay?.map(({ name, email, id }) => {
                            return (
                                <Link key={id} className='w-full flex justify-center' to={`${id}`}>
                                    <Card details={{ name, email }} />
                                </Link>
                            )
                        })
                        }

                    </div>
                    <div className='w-full flex justify-center'>
                        <Pagination pageSize={itemsPerPage} current={current} onChange={onChange} total={data?.length} />
                    </div>
                </div> :
                <div className='w-full my-24 flex justify-center items-center'>
                    <Spin size='large' />
                </div>
            }
        </div>


    )
}
export default UserManagement