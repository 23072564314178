import React, { useState, useEffect } from 'react';
import NavbarUi from './navbar/navbar';
import HeroSection from './herosection/hero';
import Features from './features/features';
import About from './about/about';
import Tokens from './tokens/tokens';
import Footer from './footer/footer';
import SectionLast from './sectionlast/last';
import loader from './assets/loadergif.gif';
import { useNavigate } from 'react-router';

function LandingPage() {
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate('/user');
        }

        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, [token, navigate]);

    if (loading) {
        return (
            <div className="loader-overlay">
                <img src={loader} alt="Loading..." />
            </div>
        );
    }

    return (
        <div className='bg-[#eee] p-0 m-0'>
            <NavbarUi />
            <HeroSection />
            <Features />
            <About />
            <Tokens />
            <SectionLast />
            <Footer />
        </div>
    );
}

export default LandingPage;
