import React from 'react';
import { FiTwitter, FiFacebook, FiInstagram } from 'react-icons/fi';

function Footer() {
    return (
        <footer className="bg-gradient-to-r from-blue-900 via-blue-800 to-blue-600 text-white py-16">
            <div className="container-fluid mx-auto px-6 lg:px-8">
          
                <div className="flex flex-col md:flex-row justify-between items-center mb-12">
                    <div className="md:text-left mb-8 md:mb-0">
                        <h6 className="text-3xl font-bold tracking-wide">The Trade Market</h6>
                        <p className="mt-3 text-gray-300 text-lg text-center">Your trusted partner in cryptocurrency trading</p>
                    </div>

                    <div className="flex space-x-6 text-2xl">
                        <a href="#" className="hover:text-gray-400 transition duration-300">
                            <FiFacebook />
                        </a>
                        <a href="#" className="hover:text-gray-400 transition duration-300">
                            <FiTwitter />
                        </a>
                        <a href="#" className="hover:text-gray-400 transition duration-300">
                            <FiInstagram />
                        </a>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-12 text-center md:text-left">
                    <div>
                        <h3 className="text-lg font-semibold mb-4 border-b-2 border-blue-300 inline-block pb-2">Features</h3>
                        <ul className="space-y-2">
                            <li className="hover:text-gray-300 transition duration-300">Easy Swap</li>
                            <li className="hover:text-gray-300 transition duration-300">Buy Cryptos</li>
                            <li className="hover:text-gray-300 transition duration-300">Convert Funds</li>
                            <li className="hover:text-gray-300 transition duration-300">Send & Receive</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 border-b-2 border-blue-300 inline-block pb-2">Resources</h3>
                        <ul className="space-y-2">
                            <li className="hover:text-gray-300 transition duration-300">Blog</li>
                            <li className="hover:text-gray-300 transition duration-300">Guides</li>
                            <li className="hover:text-gray-300 transition duration-300">Support</li>
                            <li className="hover:text-gray-300 transition duration-300">FAQs</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 border-b-2 border-blue-300 inline-block pb-2">Company</h3>
                        <ul className="space-y-2">
                            <li className="hover:text-gray-300 transition duration-300">About Us</li>
                            <li className="hover:text-gray-300 transition duration-300">Careers</li>
                            <li className="hover:text-gray-300 transition duration-300">Press</li>
                            <li className="hover:text-gray-300 transition duration-300">Contact</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold mb-4 border-b-2 border-blue-300 inline-block pb-2">Legal</h3>
                        <ul className="space-y-2">
                            <li className="hover:text-gray-300 transition duration-300">Privacy Policy</li>
                            <li className="hover:text-gray-300 transition duration-300">Terms of Service</li>
                            <li className="hover:text-gray-300 transition duration-300">Security</li>
                            <li className="hover:text-gray-300 transition duration-300">Compliance</li>
                        </ul>
                    </div>
                </div>
            
                <div className="text-center border-t border-blue-400 pt-8">
                    <p className="text-sm text-gray-400">© 2024 The Trade Market. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
