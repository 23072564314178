import { configureStore } from '@reduxjs/toolkit';
import headerReducer from '../reducer/headerSlice'
import themeReducer from '../reducer/themeSlice';
import userReducer from '../reducer/userSlice';


export default configureStore({
    reducer: {
        header: headerReducer,
        theme: themeReducer,
        user: userReducer
    }
})