import React, { useEffect, useState } from 'react';
import { LiaLongArrowAltLeftSolid } from 'react-icons/lia'
import { changeHeader } from '../../../redux/reducer/headerSlice';
import { useDispatch } from 'react-redux';
import { AiOutlineEye } from 'react-icons/ai'
import table from '../../../assests/icons/tableIcon.png'
import send from '../../../assests/icons/frame1.png'
import clip from '../../../assests/icons/clip.png'
import handleGet from '../../../utils/Get'
import handlePost from '../../../utils/Post'
import { BASE_URL } from '../../../utils/config';
import { useParams } from 'react-router';
import { Button, Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';


export default function () {
    const { id } = useParams();
    const [message, setMessage] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ticketData, setTicketData] = useState('')
    const [time, setTime] = useState(0);
    const [messageData, setMessageData] = useState({ ticket_id: id, message: '' })
    const user = JSON.parse(localStorage.getItem('user'));

    const dispatch = useDispatch();
    useEffect(() => {
        handleFetchTiketDetails()
        dispatch(changeHeader(`${user.name}, Tickets`));
    }, [])

    const handleFetchMessage = async () => {
        const data = await handleGet(`${BASE_URL}ticket/message/get/${id}`);
        setMessage(data?.message_data);
    }
    useEffect(() => {
        const intervalId = setInterval(() => {
            handleFetchMessage()
            setTime(40 * 1000)
        }, time)
        return () => {
            clearInterval(intervalId);
        }
    })

    const handlePostMessage = async () => {
        try {
            const response = await handlePost(`${BASE_URL}ticket/message/post`, messageData)
            if (response) {
                setMessageData({ message: '', ticket_id: id });
                handleFetchMessage();
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    const handleFetchTiketDetails = async () => {
        const data = await handleGet(`${BASE_URL}ticket/view/${id}`);
        console.log(data)
        setTicketData(data?.ticket)
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        Array.isArray(message) ?
            <main className='w-full h-full'>
                {ticketData && <Modal title="" open={isModalOpen} onOk={handleOk}
                    onCancel={handleCancel}
                    footer={(_, { OkBtn, CancelBtn }) => (
                        <>
                            <CancelBtn />
                            <Button onClick={handleOk}>Ok</Button>
                            {/* <OkBtn /> */}
                        </>
                    )}
                >
                    <p className='font-nunito text-2xl font-medium'>Ticket Details</p>
                    <p className='font-nunito text-lg font-medium mt-2'>Title: {ticketData?.title}</p>
                    <p className='font-nunito text-lg font-medium my-1'>Detail: {ticketData?.detail}</p>
                    <p className='font-nunito text-lg font-medium my-1'>Status: {ticketData?.status ?? 'Pending'}</p>
                </Modal>}
                <section className='w-full h-full min-h-[80vh] bg-theme-white dark:bg-dark dark:border dark:border-[#334155] rounded-xl p-4 relative'>
                    <div className='flex justify-between items-center'>
                        <Link to='/user/support' className='w-10 h-9 bg-[#E6E8F0] dark:bg-dark-body dark:border dark:border-[#334155] flex justify-center cursor-pointer items-center rounded-lg'>
                            <p className='dark:text-dark-text'><LiaLongArrowAltLeftSolid size={24} /></p>
                        </Link>
                        <div onClick={() => setIsModalOpen(true)} className='w-fit h-fit cursor-pointer px-3 py-2 bg-[#E6E8F0] dark:bg-dark-body dark:border dark:border-[#334155] rounded-lg flex gap-2 items-center'>
                            <p className='dark:text-dark-text flex gap-2 items-center'>
                                <AiOutlineEye />
                                <p className='font-nunito font-medium'>Ticket Details</p>
                            </p>
                        </div>
                    </div>
                    <div className='mb-8 mt-4'>
                        {message?.length ? message?.map(({ message, id, sender, time }) => {
                            return (
                                <div key={id} className={`w-full flex ${sender?.id === user?.id ? 'justify-start' : 'justify-end'}`}>
                                    <div>
                                        <div className={`flex items-center ${sender?.id === user?.id ? 'flex-row' : 'flex-row-reverse'} gap-4`}>
                                            <img className='w-12 h-12' src={table} alt="table" />
                                            <div className='w-fit h-fit max-w-[320px] md:min-w-[320px] min-h-[60px] bg-[#E6E8F0] dark:bg-dark-body dark:border dark:border-[#334155] dark:text-dark-text rounded-lg p-3 text-sm font-nunito flex items-center'>{message}
                                            </div>
                                        </div>
                                        <div className={`${sender?.id === user?.id ? 'text-end' : 'text-start'} text-xs text-gray-400 my-2 font-nunito`}>{time}</div>
                                    </div>
                                </div>
                            )
                        })
                            :
                            <div className='font-nunito text-2xl'>
                                NO CHATS
                            </div>
                        }
                    </div>
                    <div className='absolute bottom-4 h-10 w-full flex justify-between pr-16'>
                        <input onKeyDown={e => e.key === 'Enter' && handlePostMessage()} value={messageData?.message} onChange={e => setMessageData({ ...messageData, message: e.target.value })} className='w-full h-full border-none outline-none px-4 dark:bg-dark dark:text-dark-text' type="text" name="" id="" placeholder='Hello, How we can help you?' />
                        <div className='flex items-center gap-3'>
                            <img className='w-6 h-6 cursor-pointer' src={clip} alt="" />
                            <div onClick={handlePostMessage} className='w-11 h-9 rounded-full cursor-pointer bg-[#6c5dd3] flex justify-center items-center'>
                                <img src={send} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            :
            <div className='w-full h-[80vh] flex justify-center items-center'>
                <Spin size='large' />
            </div>
    )
}