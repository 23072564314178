import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'
import SideBar from '../../layout/usersidebar'
import Header from '../../layout/userheader'




const User = () => {

    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [showSiderbar, setShowSiderbar] = useState(false);


    const SidebarClick = () => {
        setShowSiderbar(false)
    }



    useEffect(() => {
        if (!token) {
            navigate('/login');
            return;
        }
    }, [])

    const { pathname } = useLocation();

    return (token &&
        <div className='grid grid-cols-4 bg-admin dark:bg-dark-body h-fit min-h-screen'>
            <aside className={`${showSiderbar ? 'translate-x-0' : '-translate-x-full'} fixed xl:translate-x-0 w-72 z-10 h-[100vh] overflow-hidden transition-all duration-500`}>
                <SideBar SidebarClick={SidebarClick} />
            </aside>
            <main className='col-span-4 xl:ml-[288px] ml-0 transition-all duration-500 p-8 py-2 md:p-12'>
                <section className='w-full h-16 items-center flex mb-4'>
                    <Header setShowSiderbar={setShowSiderbar} />
                </section>
                <section>
                    <Outlet />
                </section>
            </main>
        </div>
    )
}

export default User