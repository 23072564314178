import { message } from "antd";
import { BASE_URL } from "./config";
import axios from "axios";

const handleRefreshToken = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(`${BASE_URL}refresh-token`, '', {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'type': 'Bearer'
            }
        });
        if (response) {
            const newToken = response?.data?.authorisation?.token;
            await localStorage.setItem('token', newToken);
            return true;
        }
    }
    catch (err) {
        message.error("Token expired! Redirecting to login.");
        window.location.href = '/login';
        localStorage.removeItem('token')
        localStorage.removeItem('timestamp')
        localStorage.removeItem('time')
        localStorage.removeItem('user')
    }
};

export default handleRefreshToken;

