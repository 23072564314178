import React, { useEffect, useState } from 'react'
import "./index.css";
import Bitcoin from '../../../assests/icons/bitcoinimage.png'
import { Button, Spin } from 'antd';
import manage from '../../../assests/images/manage.png'
import { useDispatch } from "react-redux";
import { changeHeader } from '../../../redux/reducer/headerSlice'
import handleGet from '../../../utils/Get'
import { BASE_URL } from '../../../utils/config';
import { useNavigate } from 'react-router';
import { MdManageAccounts } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const MyAssets = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [width, setWidth] = useState('');
    const [data, setData] = useState('');
    // const [item, setItem] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { MANAGEASSETS, MyAssets, CURRENCY, BALANCE, ACTION, Manage } = t("AssetsPage", { returnObjects: true });


    useEffect(() => {
        dispatch(changeHeader(`${user.name}, ${MyAssets}`));
    }, [user, MyAssets])

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth))

        return () => window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await handleGet(`${BASE_URL}active/user/assets`);
                setData(data?.assets);
            }
            catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [])

    const handleNavigate = (value) => {
      
        navigate('manage', { state: value });
    }





    return (
        <section>
            {data ?
                <div className="">
                    <div className={`bg-theme-white dark:bg-dark dark:border dark:border-[#334155] rounded-lg pb-2 w-full ${width < 520 ? 'overflow-x-auto' : ''}`}>
                        <div className='w-full min-w-[450px]'>
                            <div className="w-full p-4">
                                <h1 className='font-nunito dark:text-white text-base md:text-2xl mb-8'>
                                    {MANAGEASSETS}
                                </h1>
                                <div className="grid grid-cols-3 lg:grid-cols-5 my-2">
                                    <span className='col-span-1 lg:col-span-3 w-full'>
                                        <button className='w-fit border-2 border-[#7EBA00] dark:border-[#7c3aed] dark:text-gray-400 px-8 py-2 rounded-xl'>{CURRENCY}</button>
                                    </span>
                                    <span className='col-span-1 w-full'>
                                        <button className='w-fit border-2 border-[#7EBA00] dark:border-[#7c3aed] dark:text-gray-400 px-8 py-2 rounded-xl'>{BALANCE}</button>
                                    </span>
                                    <span className='col-span-1 w-full flex ml-6'>
                                        <button className='w-fit border-2 border-[#7EBA00] dark:border-[#7c3aed] dark:text-gray-400 px-12 py-2 rounded-xl'>{ACTION}</button>
                                    </span>
                                </div>
                            </div>
                            <div className="w-full">
                                {Array.isArray(data) && data?.map((item, index) => {
                                    return (
                                        <div key={item?.id} >
                                            <div className={`h-12 items-center grid grid-cols-3 lg:grid-cols-5 my-2 ml-6`}>
                                                <div className='col-span-1 lg:col-span-3 w-full h-full flex items-center gap-2'>
                                                    <div className="w-12 h-12 bg-dark text-white border-[0.5px] border-opacity-60 border-gray-400 rounded-full flex justify-center items-center">
                                                        <img className="w-8 h-8 filter-class" src={item?.symbol} alt={item?.symbol} />
                                                    </div>
                                                    <div className='mt-[3px]'>
                                                        <p className='leading-3 text-base text-[#6C5DD3] dark:text-white font-semibold font-nunito'>{item?.name}</p>
                                                        <p className='text-xs text-[#6C5DD3] dark:text-gray-400 font-nunito'>{item?.short_name} ({item?.amount})</p>
                                                    </div>
                                                </div>
                                                <div className='col-span-1'>
                                                    <p className='text-xs text-[#6C5DD3] dark:text-white font-nunito'>
                                                        {item?.amount_usd === null
                                                            ? '0 (USD)'
                                                            : `${item.amount_usd} (USD)`}
                                                    </p>
                                                </div>

                                                <div className='col-span-1 flex'>
                                                    <Button onClick={() => handleNavigate(item)} className='flex items-center gap-1 py-2  bg-dark-text  font-nunito'><MdManageAccounts size={24} />{Manage}</Button>
                                                </div>
                                            </div>
                                            <div className={`${index === data?.length - 1 ? 'hidden' : 'block'} bg-hoverUser dark:border-[#334155] w-full h-[1px] my-2`} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='w-full h-[60vh] flex justify-center items-center'>
                    <Spin size='large' />
                </div>}
        </section>
    )
}

export default MyAssets