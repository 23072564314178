import React, { useEffect, useState } from 'react';
import styles from '../auth.module.css'
import LoginImage from '../../assests/icons/Group.png';
import Logo from '../../assests/icons/Group.png';
import Logo_1 from '../../assests/images/new_logo.jpg';
import { Checkbox, Divider, Input, notification } from 'antd';
import { IoMailOutline } from "react-icons/io5";
import { IoLockClosedOutline } from "react-icons/io5";
import Google_logo from '../../assests/images/google.png'
import LinkedIn_logo from '../../assests/images/linkedIn.png'
import Twitter_logo from '../../assests/images/teitter.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/config';
import bitcoin from '../../assests/icons/bit.jpg'
import login from '../../assests/images/login.png'
import OTPInput from 'react-otp-input';
import nftgif from '../../assests/images/nft.gif'


export default function Login() {
    const [userData, setUserData] = useState({ email: '', password: '', reset_code: '' });
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState({ first: true, second: false, third: false })
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const userDataLocal = localStorage.getItem('user');
    const timeStamp = localStorage.getItem('timestamp');
    // const user = JSON.parse(userDataLocal);

    const handleForgotEmail = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}send-reset-code`, { email: userData?.email });
                if (response) {
                    setStep({ second: true });
                    notification.success({ message: response?.data?.message })
                    setLoading(false)
                }
                console.log(response)
            }
            catch (error) {
                setLoading(false)
            }
        }
    }
    const handleForgot = async () => {
        if (!loading) {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}reset-password`, { ...userData });
                if (response) {
                    notification.success({ message: response?.data?.message })
                    navigate('/login');
                    setLoading(false);
                }
                console.log(response)
            }
            catch (error) {
                setLoading(false)
            }
        }
    }


    return (!token &&


        <div className="w-full h-screen overflow-hidden dark:bg-dark">
            <div className="grid grid-cols-1 lg:grid-cols-12 h-full">
                {/* Left Side - Welcome Section */}
                <div className="hidden lg:flex lg:col-span-6 flex-col justify-center items-center bg-gradient-to-br from-[#1d1f3f] to-[#18203d] p-10">
                    <div>
                        <h1 className="text-4xl font-bold text-white">Welcome to The Trade Market</h1>
                        <p className="text-lg text-gray-300 mt-4">
                            The all-in-one solution for your cryptocurrency wallet, wallet management, and wallet tracking needs.
                        </p>
                    </div>
                    <div className="mt-10">
                        <img src={nftgif} alt="Crypto Character" className="w-64 h-64 object-cover shadow-lg rounded-md" />
                    </div>
                </div>

                {/* Right Side - Form Section */}
                <div className="col-span-1 lg:col-span-6  bg-[#12122d] h-full p-5 md:p-9 overflow-x-hidden overflow-y-auto ">
                    <div className="max-w-md w-full mt-10">
                        <div className='d-flex mb-5'>
                            <Link to={'/login'} className="text-1xl text-start font-semibold text-white d-flex align-items-center">
                                <div className='w-8 me-2'>
                                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-hjmalu" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#E6E8F0" fill-opacity="0.01" d="m12 19-7-7 7-7"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m0 0 7 7m-7-7 7-7"></path></svg></svg>
                                </div>
                                <div>Go Back</div>
                            </Link>
                        </div>
                        <div className='mb-2'>
                            <h2 className="text-2xl text-start font-semibold text-white mb-2" style={{ textTransform: 'capitalize' }}> Forgot password</h2>
                            <p className="text-1xl text-start font-semibold mb-2" style={{ color: '#8F95B2' }}>Enter the email address associated with your account.</p>
                        </div>
                        <div className="py-2">
                            {step?.first && (
                                <div className="py-1">
                                    <label className="block font-nunito text-white mb-2">Email Address</label>
                                    <Input
                                        value={userData?.email}
                                        onKeyDown={(e) => e.key === 'Enter' && handleForgotEmail()}
                                        placeholder="Email Address"
                                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                        prefix={<IoMailOutline />}
                                        size="large"
                                    />
                                </div>
                            )}

                            {step?.second && (
                                <div className="py-1">
                                    <OTPInput
                                        value={userData?.reset_code}
                                        containerStyle={{
                                            margin: "auto",
                                            marginBottom: "20px",
                                            marginTop: 32,
                                            justifyContent: "center",
                                        }}
                                        onChange={(e) => setUserData({ ...userData, reset_code: e })}
                                        inputStyle={{
                                            width: "48px",
                                            padding: "12px",
                                            border: "1px solid #ACACAC",
                                            borderRadius: 12,
                                        }}
                                        numInputs={6}
                                        renderSeparator={<span style={{ marginInline: "5px" }} />}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </div>
                            )}

                            {step?.third && (
                                <div className="py-1">
                                    <label className="block font-nunito dark:text-dark-text mb-2">Enter New Password</label>
                                    <Input.Password
                                        value={userData?.password}
                                        onKeyDown={(e) => e.key === 'Enter' && handleForgot()}
                                        placeholder="Enter new password"
                                        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                        prefix={<IoLockClosedOutline />}
                                        size="large"
                                    />
                                </div>
                            )}
                        </div>

                        <div className="my-4">
                            {step?.first && (
                                <button
                                    disabled={loading}
                                    onClick={handleForgotEmail}
                                    className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}
                                >
                                    Get Code
                                </button>
                            )}

                            {step?.second && (
                                <button
                                    disabled={loading}
                                    onClick={() => (userData?.reset_code ? setStep({ third: true }) : notification.error({ message: 'Enter Code' }))}
                                    className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}
                                >
                                    Send Code
                                </button>
                            )}

                            {step?.third && (
                                <button
                                    disabled={loading}
                                    onClick={handleForgot}
                                    className={`${styles.LoginButton} ${loading ? 'opacity-70' : ''}`}
                                >
                                    Reset Password
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
